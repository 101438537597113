$t-rex-s: 90px;
$orca-s: 80px;
$bear-s: 60px;
$flamingo-s: 45px;
$frog-s: 30px;
$slug-s: 20px;
$bee-s: 10px;
$ant-s: 5px;
$gnat-s: 0;

$godzilla-l: 130px;
$blue-whale-l: 100px;
$t-rex-l: 90px;
$orca-l: 70px;
// $space-c-bear:      100px;
$flamingo-l: 60px;
$frog-l: 45px;
$slug-l: 20px;
$bee-l: 15px;
$ant-l: 5px;
$gnat-l: 0;
