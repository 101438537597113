@use "../../layout";

.scor-search-form {
    $bottom-border: solid thin layout.$black;

    margin-top: layout.$frog-s;

    > .form {
        align-items: center;
        display: flex;
        grid-column: 2 / span 12;
        > .searchinput {
            @include layout.scor-headline;
            background: none;
            border: none;
            border-bottom: $bottom-border;
            font-size: 40px;
            padding-bottom: layout.$bee-s;
            width: 100%;

            &:focus {
                outline: 2px solid layout.$cruise;
            }
        }

        > .searchinput::placeholder {
            @include layout.scor-headline;
        }

        > .searchsubmit,
        > .searchreset {
            @include layout.unstyled-button;
        }

        > .searchreset {
            align-items: center;
            border-bottom: $bottom-border;
            display: flex;
            flex: 0 0 28px;
            height: 100%;
            justify-content: center;
        }

        > .searchreset > svg {
            height: 100%;
            width: 100%;
        }

        > .searchsubmit {
            @include layout.visually-hidden;
        }
    }
}

@include layout.mqs-mq-medium {
    .scor-search-form {
        > .form {
            > .searchinput {
                @include layout.scor-headline-m;
                font-size: 80px;
            }

            > .searchinput::placeholder {
                @include layout.scor-headline-m;
            }

            > .searchreset {
                flex: 0 0 55px;
            }
        }
    }
}
