@use "../../layout";

/* stylelint-disable max-nesting-depth, selector-class-pattern */
.bmc-footer {
    background-color: layout.$mischka;
    color: layout.$red-ribbon;
    font-family: layout.$main-font;
    font-size: layout.$bee-l;
    margin-top: layout.$flamingo-s;

    > .footer-content {
        grid-column: 2 / 12;
        margin-bottom: layout.$frog-l;
    }

    > .footer-content > .footer-col-group {
        border-top: 1px solid layout.$red-ribbon;
        padding-bottom: 1.5rem;
        padding-top: 1.5rem;
        width: 100%;
    }

    > .footer-content > .footer-col-group:first-of-type {
        border-top: none;
        padding: 0;
    }

    > .footer-content > .footer-col-group > .footer-col.-socials {
        width: 100%;
    }

    .footer-col {
        width: 50%;
        &.-socials {
            grid-column: 2 / 12;
            margin-top: layout.$frog-l;
        }
        &.-socials > .footer-socials-content {
            display: block;
            margin-bottom: layout.$frog-s;
            margin-top: layout.$bee-s;
            width: 100%;
        }
        &.-socials > .footer-socials-content > .footer-socials-icons {
            align-items: center;
            display: flex;
            margin-top: 10px;
            width: 20%;
        }
        &.-socials > .footer-socials-content > .footer-socials-newsletter {
            display: block;
            margin-top: 10px;
        }

        // stylelint-disable selector-max-compound-selectors
        &.-socials > .footer-socials-content > .footer-socials-newsletter {
            > .newsletter-link {
                color: layout.$red-ribbon;
                font-weight: bold;
                text-decoration: none;
            }

            > .signup-form {
                border: 1px solid layout.$red-ribbon;
                margin-top: 20px;
            }

            > .signup-form > .input,
            > .signup-form > .submit {
                color: layout.$red-ribbon;
            }
        }
        // stylelint-enable selector-max-compound-selectors
        &.-socials > .footer-socials-wrapper {
            display: inline-flex;
            width: 100%;
        }
        &.-socials > .footer-socials-wrapper > .footer-socials-headline {
            font-weight: bold;
        }
        &.-list {
            display: inline-grid;
            list-style: none;
            padding: 0;
            row-gap: 15px;
        }
        &.-list a {
            color: layout.$red-ribbon;
            text-decoration: none;
        }
    }

    > .footer-content > .footer-col-group .-headline {
        font-weight: bold;
    }

    > .footer-bottom-row {
        align-items: center;
        background: layout.$white;
        color: layout.$fuscous-gray;
        font-weight: bold;
        grid-column: 1 / -1;
        width: 100%;
    }

    > .footer-bottom-row > .bmc-copyright {
        grid-column: 2 / span 8;
        grid-row: 2;
        line-height: 30px;
        margin-top: 20px;
    }

    > .footer-bottom-row > .bmc-legals {
        grid-column: 2 / -1;
        grid-row: 3;
        margin-top: 20px;
        padding: 0;
    }

    > .footer-bottom-row > .bmc-logo {
        grid-column: 2 / -1;
        margin-top: 20px;
        width: 73px;
    }

    > .footer-bottom-row .legal-list {
        padding: 0;
        .legal-list-item {
            cursor: pointer;
            display: inline-flex;
            list-style: none;

            &:not(:last-child) {
                margin-right: 50px;
            }
        }

        .legal-list-link {
            color: layout.$fuscous-gray;
            outline: none;
            text-decoration: none;
        }
    }
}

@include layout.mqs-mq-medium {
    .bmc-footer {
        margin-top: layout.$orca-l;

        .footer-col {
            &.-socials > .footer-socials-content > .footer-socials-newsletter {
                display: block;
                margin-top: -27px;
            }

            &.-socials > .footer-socials-content {
                display: inline-flex;
            }
        }

        > .footer-content {
            display: inline;
            width: 100%;
        }

        > .footer-content > .footer-col-group {
            border-top: none;
            display: inline;
        }

        > .footer-content > .footer-col-group > .footer-col {
            margin-top: layout.$frog-s;
            width: 100%;
        }

        > .footer-content > .footer-col-group > .footer-col:not(.-socials) {
            width: 20%;
        }

        > .footer-content > .footer-col-group:first-of-type {
            border-bottom: 1px solid layout.$red-ribbon;
            display: block;
            padding: 0;
            width: 100%;
        }

        .footer-bottom-row {
            align-items: center;
            background: layout.$white;
            color: layout.$fuscous-gray;
            font-weight: bold;
            grid-column: 1 / -1;
            height: 52px;
            width: 100%;
        }

        > .footer-bottom-row > .bmc-copyright {
            grid-column: 2 / span 5;
            grid-row: 1;
            margin-top: 0;
        }

        > .footer-bottom-row > .bmc-legals {
            grid-column: 9 / span 5;
            grid-row: 1;
            margin-top: 0;
        }

        > .footer-bottom-row > .bmc-logo {
            grid-column: 1 / -1;
            grid-row: 1;
            margin-top: 0;
            width: 53px;
        }

        > .footer-bottom-row > .legal-list > .legal-list-item {
            cursor: pointer;
            display: inline-flex;
            list-style: none;

            &:not(:last-child) {
                margin-right: (layout.$bear-s + 18px);
            }
        }
    }
}
/* stylelint-enable max-nesting-depth, selector-class-pattern */
