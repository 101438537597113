@use "../../layout";

$special-feature-height: 160vw;
.special-feature {
    align-items: center;
    display: grid;
    justify-content: center;
    margin-top: 80px;

    > .foreground,
    > .background {
        grid-column: 1 / -1;
        grid-row: 1;
    }

    > .foreground {
        display: grid;
        gap: 0 4px;
        grid-template-columns: 16px repeat(12, 1fr) 16px;
        min-height: 370px;
        z-index: 3;

        > .content {
            align-items: center;
            display: flex;
            flex-direction: column;
            grid-column: 3 / span 10;
            grid-row: 1;
            justify-content: center;
            text-align: center;
            z-index: 2;
        }

        > .content > .headline,
        > .content > .copy {
            @include layout.bw-text;
        }

        > .content > .headline {
            @include layout.adicta-headline;
            font-size: 20px;
        }

        > .content > .copy {
            @include layout.adicta-copy;
            font-size: 13px;
        }

        > .content > .adicta-cta {
            margin-top: layout.$slug-s;
        }

        > .responsive-picture {
            grid-column: 1 / -1;
            grid-row: 1;
            min-height: inherit;
        }
    }

    > .background {
        display: grid;
        grid-template-columns: repeat(12, 1fr);
        max-height: $special-feature-height;
        overflow: hidden;
        position: relative;

        > .filter,
        > .responsive-picture {
            grid-column: 1 / -1;
            grid-row: 1;
            max-height: inherit;
        }

        > .filter {
            height: 100%;
            z-index: 2;
        }

        > .responsive-picture {
            z-index: 1;
        }
    }

    &.-blackandwhite {
        filter: grayscale(100%);
    }
}

@include layout.mqs-mq-medium {
    .special-feature {
        $grid-gap: 40px;
        gap: 0 $grid-gap;
        grid-template-columns: $grid-gap repeat(12, 1fr) $grid-gap;
        margin-top: 120px;

        // @TODO: Get the grids to line up
        > .foreground {
            grid-column: 3 / span 10;
            grid-gap: 0 $grid-gap;
            grid-template-columns: $grid-gap repeat(12, 1fr) $grid-gap;

            > .content > .headline {
                @include layout.adicta-headline-m;
                font-size: 40px;
            }

            > .content > .copy {
                font-size: 16px;
            }

            > .responsive-picture {
                max-height: 400px;
            }
        }

        > .background {
            // Component max height
            max-height: 750px;
        }
    }
}
