@use "./media-queries" as mqs;
@use "./spaces";

$max-content-width: 1440px;

$grid-s-margin: 12px;

$margin-s-fullscreen: spaces.$gnat-s;
$margin-l-fullscreen: spaces.$gnat-l;

$margin-s-almost-full: spaces.$slug-s;
$margin-l-almost-full: spaces.$slug-l * 2;

$margin-l-content: spaces.$blue-whale-l;
$margin-s-content: spaces.$slug-s;

%grid-s {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.grid {
    @extend %grid-s;

    &.-almost-full {
        grid-template-columns: $margin-s-almost-full repeat(12, 1fr) $margin-s-almost-full;
    }

    &.-copy {
        grid-template-columns: $margin-s-content repeat(12, 1fr) $margin-s-content;
    }
}

@include mqs.mq-medium {
    .grid {
        grid-template-columns: repeat(12, 1fr);

        &.-almost-full {
            grid-template-columns: $margin-l-almost-full repeat(12, 1fr) $margin-l-almost-full;
        }

        &.-copy {
            grid-template-columns: $margin-l-content repeat(12, 1fr) $margin-l-content;
        }
    }
}

%content-width {
    grid-template-columns: $grid-s-margin repeat(12, 1fr) $grid-s-margin;
    max-width: $max-content-width;
}

.content-area {
    @extend %content-width;
}

.makeover-body {
    display: flex;
    flex-direction: column;

    margin: 0;
    padding: 0;

    > .content-area {
        align-self: center;
    }

    // Since React Habitat wraps our components in a </div> we need
    // to allow the sticky containers to still function
    .sticky-passthrough {
        display: contents;
    }
}
