@use "../../../layout";

.signup-form {
    align-items: center;
    border: 1px solid layout.$white;
    display: flex;
    justify-content: space-between;
    margin-top: 0;

    > .label {
        @include layout.visually-hidden;
    }

    > .input,
    > .submit {
        color: layout.$white;
        font-family: layout.$main-font;
        font-size: 16px;
    }

    > .input {
        background: none;
        border: none;

        outline: none;
        padding: 5px 15px;
        width: 90%;
    }

    > .input::placeholder {
        opacity: 1;
    }

    > .submit {
        @include layout.unstyled-button;
        color: layout.$white;
        cursor: pointer;
        font-weight: bold;
        padding: layout.$bee-s layout.$slug-s;
    }
}
