@use "../../layout";

.makeover-brand-teaser {
    background-color: layout.$mischka;
    grid-template-rows: repeat(6, auto);
    isolation: isolate;
    margin-top: layout.$slug-s;
    padding-bottom: layout.$slug-s;

    > .create-speed,
    > .brand-teaser-pictures {
        grid-column: 1 / -1;
        grid-row: 1 / -1;
    }

    > .brand-teaser-pictures {
        z-index: 5;
    }

    > .ctacontainer {
        grid-column: 1 / -1;
        margin-left: layout.$frog-s;
    }
}

@include layout.mqs-mq-medium {
    .makeover-brand-teaser {
        background: linear-gradient(
            to right,
            layout.$white calc(0% + 40px),
            layout.$mischka calc(0% + 40px) calc(100% - 40px),
            layout.$white calc(100% - 40px)
        );
        margin-top: layout.$blue-whale-l;
        padding-bottom: layout.$slug-l + layout.$bee-l;

        > .ctacontainer {
            grid-column: 3 / -1;
            margin-left: layout.$gnat-l;
        }
    }
}
