@use "../../../layout";

.adicta-support-item {
    @media screen and (prefers-reduced-motion: reduce) {
        > .link {
            align-items: center;
            color: layout.$white;

            display: flex;
            flex-direction: column;
            justify-content: center;
            text-decoration: none;

            transition: none;

            &:hover,
            &:focus {
                scale: 1.1;
            }
        }
    }
    > .link {
        align-items: center;
        color: layout.$white;

        display: flex;
        flex-direction: column;
        justify-content: center;
        text-decoration: none;

        transition: transform 0.3s ease-out;

        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }

    > .link > .icon {
        g {
            fill: layout.$white;
        }
    }

    > .link > .label {
        margin: layout.$bee-l layout.$gnat-s layout.$gnat-s;
        text-transform: uppercase;
    }
}
