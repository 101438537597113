@use "../../../layout";

$highlight-item-content-alignment: 50%;

.bmc-highlight-item {
    display: grid;
    max-height: 700px;
    position: relative;

    > .responsive-picture {
        grid-column: 1 / -1;
        grid-row: 1;
        max-height: 700px;
        min-height: 360px;

        //stylelint-disable-next-line
        .splide__slide & &::before,
        &::before {
            background: layout.$red-ribbon;
            content: "";
            display: block;
            height: 100%;
            opacity: 0;
            position: absolute;
            width: 100%;
        }

        //stylelint-disable-next-line
        .splide__slide.is-active &::before {
            animation: visibility 0.2s linear forwards;
        }
    }

    > .content-wrapper {
        align-self: center;
        grid-column: 1 / -1;
        grid-row: 1;
        padding: 0 20px;
        text-align: center;
        visibility: hidden;
        z-index: 1;

        //stylelint-disable selector-class-pattern
        .splide__slide.is-active & {
            visibility: visible;
        }

        > .icon {
            width: 100px;
        }

        > .headline,
        > .copy {
            @include layout.rwb-text;
            text-align: left;
        }

        > .headline {
            font-family: layout.$main-font;
            font-size: layout.$slug-s;
            font-weight: bold;
            margin: 10px 0;
            max-width: 80%;
        }

        > .copy {
            font-size: layout.$bee-s;
        }

        // stylelint-disable a11y/media-prefers-reduced-motion
        > .cta {
            margin-top: 10px;
            width: calc((#{$highlight-item-content-alignment} * 2) - 70px);
        }
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .bmc-highlight-item {
        &:hover,
        &:focus {
            > .responsive-picture::before {
                animation: visibility 0.2s linear forwards;
            }
        }
    }
}

@include layout.mqs-mq-medium {
    .bmc-highlight-item {
        &:hover,
        &:focus {
            > .responsive-picture::before {
                animation: visibility 0.2s linear forwards;
            }

            > .content-wrapper {
                visibility: visible;
            }
        }

        > .responsive-picture {
            min-height: 600px;
        }

        > .content-wrapper {
            padding: 0 40px;

            > .headline {
                font-size: layout.$frog-s;
                margin: 30px 0;
            }

            > .copy {
                font-size: 15px;
            }

            > .icon {
                width: 150px;
            }

            > .cta .text {
                font-size: layout.$slug-s;
            }
        }
    }
}
