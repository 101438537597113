@use "../../layout";

/* stylelint-disable selector-class-pattern */
@mixin product-overview-slider {
    > .wrapper {
        max-width: 100%;
    }

    .splide {
        max-width: calc(100vw - 40px);
    }

    .splide__pagination {
        bottom: -(layout.$bear-s);
        justify-content: center;
        padding: 0;
    }

    .splide__pagination__page {
        all: unset;
        cursor: default;
        font-family: layout.$main-font;
        font-size: 16px;
        font-weight: bold;
        margin-right: layout.$slug-s;
        opacity: 0.45;

        &.is-active {
            opacity: 1;
        }
    }

    .splide__pagination > li:last-child > .splide__pagination__page {
        margin-right: layout.$gnat-s;
    }
}

@mixin product-overview-slider-m {
    .splide__pagination {
        bottom: -(layout.$t-rex-l);
        justify-content: center;
        width: 100%;
    }
}
/* stylelint-enable selector-class-pattern */

.adicta-product-overview {
    margin-top: layout.$bear-s;
    padding-bottom: layout.$bear-s;

    > .headline {
        @include layout.adicta-headline;
        font-size: 40px;
        text-align: center;
    }

    > .headline,
    > .content-slider {
        grid-column: 2 / span 12;
    }

    > .content-slider {
        @include product-overview-slider();
        margin-top: layout.$flamingo-s;
    }
}

@include layout.mqs-mq-medium {
    .adicta-product-overview {
        margin-top: layout.$godzilla-l;
        padding-bottom: layout.$t-rex-l;

        > .headline {
            @include layout.adicta-headline-m;
            display: block;
        }

        > .headline,
        > .content-slider {
            grid-column: 4 / span 8;
        }

        > .content-slider {
            @include product-overview-slider-m();
            margin-top: layout.$t-rex-l;
        }
    }
}
