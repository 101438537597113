@use "../../layout";

.home-slider {
    $max-height: 600px;
    font-family: layout.$main-font;

    > .responsive-picture,
    > .content {
        grid-row: 1;

        > .page-headline {
            line-height: layout.$frog-l;
        }
    }

    > .responsive-picture {
        grid-column: 1 / -1;
        max-height: $max-height;
        min-height: $max-height;
        z-index: 1;
    }

    > .content {
        grid-column: 3 / span 8;
        z-index: 2;

        > .motto {
            @include layout.rwb-text();
            font-size: layout.$slug-s;
            font-weight: bold;

            margin: 0;
            margin-top: layout.$bee-s;
        }
    }
}

@include layout.mqs-mq-medium {
    .home-slider {
        $max-height: 750px;

        grid-template-rows: minmax(auto, $max-height);

        > .responsive-picture {
            max-height: 750px;
        }

        > .content {
            > .motto {
                font-size: layout.$frog-l;
                margin-top: layout.$slug-l;
            }

            > .page-headline {
                line-height: layout.$blue-whale-l;
            }
        }

        &.-right {
            > .content {
                grid-column: 7 / span 6;
                text-align: right;
            }
        }

        // @TODO: This needs to be fixed
        &.-center {
            > .content {
                align-self: center;
                grid-column: 4 / span 8;
                text-align: center;
            }

            > .content > .page-headline {
                margin-top: 0;
            }
        }
    }
}
