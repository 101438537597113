@use "../../layout";

.intended-use {
    letter-spacing: 2px;
    margin-top: layout.$bear-s;
    text-align: center;
    text-transform: uppercase;

    > .headline {
        @include layout.adicta-headline;
        font-size: 20px;
        grid-column: 4 / span 6;
    }

    > .items {
        display: block;
        grid-column: 1 / span 12;
        justify-content: center;

        margin-top: layout.$gnat-s;
    }
}

@include layout.mqs-mq-medium {
    .intended-use {
        margin-top: layout.$t-rex-l;

        > .headline {
            font-size: 45px;
            grid-column: 5 / span 4;
        }

        > .items {
            display: flex;
            grid-column: 2 / span 10;
            justify-content: space-between;

            margin-top: layout.$frog-s;
        }
    }
}
