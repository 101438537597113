@use "../../layout";

/* stylelint-disable */
@mixin makeover-pagination {
    .splide__pagination {
        all: unset;
        display: flex;
        flex-direction: row;
        justify-content: center;
        margin-top: layout.$slug-s;

        > li,
        > li > .splide__pagination__page {
            max-width: 60px;
            width: 100%;
        }

        > li:not(:last-child) {
            margin-right: layout.$bee-s;
        }

        .splide__pagination__page {
            @include layout.horizonal-line-background(
                layout.$silver-chalice,
                2px
            );
            border-radius: none;
            margin: layout.$gnat-s;
            padding: layout.$slug-s;

            &.is-active {
                @include layout.horizonal-line-background(
                    layout.$red-ribbon,
                    2px
                );
                transform: unset;
            }
        }
    }
}

.makeover-image-slider {
    margin-top: 0;

    > .wrapper {
        grid-column: 2 / span 12;
        overflow: hidden;

        > .splide {
            @include makeover-pagination();

            .splide__slide img {
                width: 100%;
                height: auto;
            }

            // @TODO: Needs to be corrected
            > .splide__arrows {
                display: flex;
                justify-content: space-between;
                align-items: center;
                position: absolute;
                width: 100%;
                transform: translateY(-50%);
                top: 50%;
                z-index: 1;

                > .splide__arrow {
                    background: none;
                    border: none;
                    padding: 0;
                    height: 17px;

                    > svg {
                        height: 100%;
                        width: 100%;
                    }

                    &--prev {
                        transform: rotate(-180deg);
                    }
                }
            }
        }
    }
}
/* stylelint-enable */
