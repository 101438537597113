@use "../../layout";
@use "../../features/scor/layout/colors" as scor-colors;

.sticky-link-bar {
    $closesvg-width: 24px;
    align-items: center;
    display: flex;
    height: 40px;
    justify-content: space-between;
    padding-left: 6px;

    > .closeform {
        flex: 0 0 $closesvg-width;

        > button {
            background: none;
            border: none;
            color: currentColor;
        }
    }

    > .links {
        align-items: center;
        display: flex;
        flex: 2;
        justify-content: space-around;

        > .bmc-sticky-link:first-child {
            margin-left: -$closesvg-width;
        }
    }

    &.-adicta {
        background-color: layout.$black;
        color: layout.$white;
    }

    &.-makeover {
        background-color: layout.$red-ribbon;
        color: layout.$white;
    }

    &.-scor {
        background-color: layout.$black;
        color: scor-colors.$cruise;
        font-weight: bold;
    }
}
