@use "../../layout";

.adicta-text-module {
    @include layout.adicta-copy;
    margin-top: layout.$frog-s; // according to customer feedback: margin-top has been adjusted to makeover-styling
    > .headline,
    > .content {
        font-size: layout.$slug-s;
        font-weight: 700;
    }
    > .headline {
        @include layout.adicta-headline;
    }
    > .content {
        margin: 0;
    }

    > .headline + .content {
        margin-top: layout.$bee-s;
    }

    &.-center {
        text-align: center;
        > .headline {
            grid-column: 3 / span 10;
        }

        > .content {
            grid-column: 2 / span 12;
        }
    }

    &.-left {
        text-align: left;
        > .headline {
            grid-column: 2 / span 12;
        }

        > .content {
            grid-column: 2 / span 12;
        }
    }
}

@include layout.mqs-mq-medium {
    .adicta-text-module {
        // according to customer feedback: margin-top has been adjusted to makeover-styling
        margin-top: layout.$t-rex-l;
        > .headline,
        > .content {
            font-size: layout.$frog-s;
        }
        &.-left {
            > .headline {
                grid-column: 2 / span 5;
            }
        }
    }
}
