@use "../../layout";

.page-header-makeover {
    align-items: center;
    background-color: layout.$white;
    color: layout.$mine-shaft;
    grid-template-rows: layout.$bear-s 1fr layout.$bear-s;
    height: layout.$bear-s;
    position: sticky;
    top: 0;
    transform: translateY(0);
    transition: transform 100ms cubic-bezier(0.1, 0.2, 0.3, 0.4);
    z-index: layout.$sticky-navigation;

    > .menubutton,
    > .icons > .search,
    > .icons > .store-switcher-toggle {
        @include layout.unstyled-button;
    }

    > .icons > .store-switcher-toggle > .regionflag {
        height: 100%;
        margin-top: 3px;
        width: 24px;
    }

    > .menubutton {
        background-image: url("../../assets/navigation/menu.svg");
        background-repeat: no-repeat;
        display: block;
        grid-column: 2;
        grid-row: 1;
        height: 24px;
        padding: 1rem;
        padding: 0;
        text-align: left;
        width: 24px;

        &[aria-expanded="true"] {
            background-image: url("../../assets/navigation/close.svg");
        }
    }

    // @TODO: Extract into its own component
    > .icons > .makeover-minicart,
    > .makeover-minicart {
        $size: 28px;
        display: grid;
        grid-template-columns: $size;
        grid-template-rows: $size;
        justify-content: center;
        margin-top: -2px;

        > .bmc-icon-link,
        > .notification-badge {
            grid-column: 1;
            grid-row: 1;
        }

        > .notification-badge {
            align-self: end;
            justify-self: center;

            // Since the notification badge is over the bmc-icon-link
            // we want the click to go through to the link
            pointer-events: none;
        }
    }

    > .icons {
        align-items: flex-start;
        background-color: layout.$white;
        display: flex;
        grid-column: 1 / -1;
        grid-row: 3;
        height: 100%;

        // @TODO: Integrate the grid instead of using
        // padding
        padding-left: 20px;

        > .bmc-icon-link > .account,
        > .search,
        > .bmc-icon-link {
            width: 24px;
        }

        > .bmc-icon-link,
        > .search,
        > .makeover-minicart {
            margin-left: 24px;
        }

        // Don't show the checkout on mobile as it's
        // already in the first row
        > .makeover-minicart {
            display: none;
        }
    }

    > .logo {
        grid-column: 7 / span 2;
        justify-self: center;
    }

    > .logo + .makeover-minicart {
        grid-column: -3;
        justify-self: end;
    }

    /* stylelint-disable a11y/media-prefers-reduced-motion */
    > .navigation,
    > .search-container {
        background-color: layout.$white;
        grid-column: 1 / -1;
        grid-row: 2;
        overflow-y: scroll;
        padding-right: layout.$slug-s;
        position: relative;
        transition: height 100ms ease-in-out;
    }
    /* stylelint-enable a11y/media-prefers-reduced-motion */

    > .search-container {
        padding-right: 0;
    }

    // @TODO: Due to the inconsistent designs we need to clean
    // this logic up at a later point regarding the icons
    /* stylelint-disable-next-line selector-class-pattern */
    > .navigation._hidden ~ .icons {
        display: none;
    }

    > .search-container.-shown ~ .icons {
        display: flex;
    }

    /* stylelint-disable a11y/media-prefers-reduced-motion */
    &.-show-store-switcher {
        transform: translateY(41px);
    }
    /* stylelint-enable a11y/media-prefers-reduced-motion */
}

@include layout.mqs-mq-medium {
    .page-header-makeover {
        align-items: center;
        grid-template-rows: layout.$flamingo-l auto;
        height: layout.$flamingo-l;

        > .logo + .makeover-minicart {
            display: none;
        }

        > .navigation {
            padding-bottom: layout.$frog-l;

            .desktop-list {
                padding-left: layout.$slug-l * 2;
            }
        }

        > .navigation > .search-container {
            height: auto;
            min-height: 636px;
            overflow-y: auto;
            padding-right: 0;
        }

        > .navigation,
        > .search-container {
            height: auto;
            overflow-y: unset;
        }

        /* stylelint-disable-next-line selector-class-pattern */
        > .navigation._hidden ~ .icons {
            display: flex;
        }

        > .icons {
            align-items: center;
            grid-column: 10 / span 4;
            grid-row: 1;
            justify-content: end;

            > .makeover-minicart {
                display: grid;
            }
        }

        > .icons > .search,
        > .store-switcher-toggle {
            margin-top: 3px;
        }

        > .logo {
            grid-column: 7 / span 2;
            justify-self: center;
        }

        > .navigation > .menu-list > .level {
            grid-row: 1 / span 2;
            height: auto;
        }
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .page-header-makeover {
        transition: none;

        &.-show-store-switcher {
            transform: none;
        }

        > .navigation,
        > .search-container {
            transition: none;
        }
    }
}
