@use "../../layout";

.bmc-category-header {
    align-items: flex-start;
    font-family: layout.$main-font;

    > .content {
        grid-column: 2 / 12 span;
        grid-row: 1;
        margin-top: layout.$slug-s;
        z-index: 1;

        > .category-headline,
        > .category-description {
            @include layout.rwb-text();
            font-family: layout.$main-font;
            font-weight: 700;
        }

        > .category-headline {
            font-size: 30px;
            margin-top: layout.$flamingo-s;
        }

        > .category-description {
            font-size: 20px;
            line-height: 25px;
            margin-top: layout.$slug-s;
            padding-bottom: layout.$bear-s;
        }
    }

    > .responsive-picture {
        grid-column: 1 / -1;
        grid-row: 1;
        height: 100%;
        max-height: 500px;
        object-fit: cover;
        width: 100%;
    }
}

@include layout.mqs-mq-medium {
    .bmc-category-header {
        > .content {
            grid-column: 2 / span 6;

            > .category-headline {
                font-size: 45px;
                margin-top: layout.$orca-l;
            }

            > .category-description {
                margin-top: layout.$slug-l;
            }
        }
    }
}
