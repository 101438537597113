@use "../../../../layout";

.desktop-list {
    list-style: none;

    margin: 0;
    padding: 0;
    padding-left: layout.$frog-s;
    position: absolute;

    visibility: visible;

    &.hidden {
        visibility: hidden;
    }

    &[data-parent="secondary"] {
        bottom: 0;
        font-size: 1rem;
    }
}
