@use "../../layout";

@include layout.mqs-mq-medium {
    .support-links {
        margin-top: layout.$frog-l;

        > .topics {
            @include layout.unlist-a-list;

            > .topic {
                font-family: layout.$main-font;
                font-size: 20px;
                margin-top: layout.$bee-l * 2;
            }

            > .topic > .link {
                @include layout.linkcolor(layout.$mine-shaft);
                text-decoration: none;
            }

            > .topic.-is-active > .link {
                font-weight: bold;
            }
        }
    }
}
