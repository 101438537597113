@use "../../layout";

.scor-teaser-product-story {
    display: grid;
    grid-template-columns: layout.$gnat-s repeat(12, 1fr) layout.$gnat-s;
    justify-content: center;
    margin-top: layout.$bear-s;
    position: relative;

    > .headline {
        @include layout.scor-headline();
        font-size: 45px;
        grid-column: 3 / span 1;
        grid-row: 2 / span 1;
        margin: 0;
        position: absolute;
        z-index: 1;
    }

    > .responsive-picture {
        grid-column: 1 / -1;
        grid-row: 1 / span 6;
        max-height: 320px;
    }

    > .content {
        display: grid;
        grid-column: 3 / span 10;
        grid-row: 7 / span 5;
        grid-template-columns: repeat(3, 1fr);
        grid-template-rows: repeat(1, 1fr);

        text-align: center;

        > .copy {
            font-family: layout.$main-font;
            font-size: 20px;
            font-weight: bold;
            grid-column: 1 / -1;
            grid-row: 1 / span 1;
            margin-top: layout.$bear-s;
        }
        > .scor-cta {
            grid-column: 1 / 4;
            grid-row: 4 / 4;
        }
    }
}

@include layout.mqs-mq-medium {
    .scor-teaser-product-story {
        margin-top: layout.$godzilla-l;
        > .headline {
            @include layout.scor-headline-m();
            font-size: 80px;
            font-weight: bold;
            grid-row: 2 / span 1;
        }

        > .responsive-picture {
            grid-column: 1 / -1;
            grid-row: 1 / span 6;
            max-height: 772px;
        }

        > .content {
            > .copy {
                font-size: 24px;
                font-weight: bold;
            }
            > .scor-cta {
                grid-column: 2 / 3;
                grid-row: 4 / 4;
            }
        }
    }
}
