@use "../../layout";

@media screen and (prefers-reduced-motion: reduce) {
    .adicta-cta {
        display: block;
        font-size: 12px;

        margin-top: layout.$flamingo-s;
        padding: layout.$slug-s layout.$frog-s;

        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: none;

        &.-white {
            background-color: layout.$white;
            border: 1px solid transparent;
            color: layout.$black;

            &:hover,
            &:focus {
                background-color: layout.$black;
                color: layout.$white;
            }
        }

        &.-black {
            background-color: layout.$black;
            border: 1px solid transparent;
            color: layout.$white;

            &:hover,
            &:focus {
                background-color: layout.$white;
                border-color: currentColor;
                color: layout.$black;
            }
        }

        &.-clear-black {
            background-color: transparent;
            border: 1px solid currentColor;
            color: layout.$black;

            &:hover,
            &:focus {
                background-color: layout.$black;
                color: layout.$white;
            }
        }

        &.-clear-white {
            background-color: transparent;
            border: 1px solid currentColor;
            color: layout.$white;

            &:hover,
            &:focus {
                background-color: layout.$white;
                color: layout.$black;
            }
        }
    }
}

.adicta-cta {
    display: block;
    font-size: 15px;

    margin-top: layout.$flamingo-s;
    padding: layout.$bee-l layout.$frog-s;

    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 200ms ease-in-out, color 250ms ease-in;

    &.-white {
        background-color: layout.$white;
        border: 1px solid transparent;
        color: layout.$black;

        &:hover,
        &:focus {
            background-color: layout.$black;
            color: layout.$white;
        }
    }

    &.-black {
        background-color: layout.$black;
        border: 1px solid transparent;
        color: layout.$white;

        &:hover,
        &:focus {
            background-color: layout.$white;
            border: 1px solid transparent;
            color: layout.$black;
        }
    }

    &.-clear-black {
        background-color: transparent;
        border: 1px solid layout.$black;
        color: layout.$black;

        &:hover,
        &:focus {
            background-color: layout.$black;
            border: 1px solid layout.$black;
            color: layout.$white;
        }
    }

    &.-clear-white {
        background-color: transparent;
        border: 1px solid currentColor;
        color: layout.$white;

        &:hover,
        &:focus {
            background-color: layout.$white;
            color: layout.$black;
        }
    }
}
