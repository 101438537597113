@use "../../../layout";

.progress-bar {
    width: 200px;

    > .joist {
        background: layout.$black;
        height: 1.6px;
        position: relative;
        top: layout.$ant-s;
        width: 100%;
    }
    @media screen and (prefers-reduced-motion: reduce) {
        > .fill {
            background: layout.$black;
            display: block;
            height: 7px;
            max-width: 100%;
            transition: none;
        }
    }

    > .fill {
        background: layout.$black;
        display: block;
        height: 7px;
        max-width: 100%;
        transition: width 1s;
    }
}
