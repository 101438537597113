@use "./fonts";

@mixin scor-headline {
    font-family: fonts.$headline-font;

    // @NOTE: Not sure if this is what design meant with "2"
    // but it looks correct.
    letter-spacing: 2px;
    margin: 0;
    text-transform: uppercase;
}

@mixin scor-headline-m {
    letter-spacing: 10px;
}
