@use "../../../layout";

.adicta-product {
    margin-top: 0;

    // @NOTE: To show the border
    padding-bottom: 3px;

    > .headline,
    > .copy {
        font-family: layout.$main-font;
        margin: 0;
    }

    > .headline {
        @include layout.adicta-headline;
        font-size: 20px;
        margin-top: layout.$flamingo-s;
    }

    > .copy {
        font-size: 12px;
        margin-top: layout.$bee-s;
    }

    > .underlined-link {
        margin-top: layout.$frog-s;
    }
}

@include layout.mqs-mq-medium {
    .adicta-product {
        text-align: center;

        > .headline {
            @include layout.adicta-headline-m;
        }

        > .headline,
        > .copy {
            font-size: 15px;
        }
    }
}
