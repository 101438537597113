@use "../../layout/fonts";
@use "../../layout/spaces";
@use "../../layout/colors";
@use "../../layout/media-queries" as mqs;

.page-headline {
    @include colors.rwb-text();

    font-family: fonts.$main-font;
    font-size: spaces.$frog-s;
    font-weight: bold;

    margin: 0;
    margin-top: spaces.$flamingo-s;
    padding: 0;
}

@include mqs.mq-medium() {
    .page-headline {
        font-size: spaces.$t-rex-l;

        margin-top: spaces.$blue-whale-l;
    }
}
