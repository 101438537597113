@use "../../layout";

.cta {
    @extend %cta-link;
    @include layout.gradient-right();
    @include layout.rwb-text();
    font-family: layout.$main-font;

    font-size: 1rem;
    margin-top: layout.$frog-s;

    &.-red {
        color: layout.$red-ribbon;
    }

    &.-white {
        background-color: transparent;
        color: layout.$white;

        &:hover,
        &:focus {
            background-image: linear-gradient(currentColor, currentColor);

            > .text {
                color: layout.$black;
            }
        }
    }
}

@include layout.mqs-mq-medium {
    .cta {
        font-size: 20px;
        margin-top: layout.$slug-l * 2;
    }
}
