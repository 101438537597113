$black: #000000;
$white: #ffffff;

// @NOTE: No idea where this is used
$mine-shaft: #353535;

// @NOTE: Design defines #F01414 but then uses FF3A30 for the links...
$red-orange: #ff3a30;

/* stylelint-disable plugin/stylelint-no-indistinguishable-colors */
@mixin bw-text {
    &.-white {
        color: $white;
    }

    &.-black {
        color: $black;
    }
}
