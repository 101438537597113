@use "../../layout";

.product-description {
    $product-description-min-height: 52.778vw;
    display: grid;
    grid-template-columns: 14px repeat(12, 1fr) 14px;
    grid-template-rows: repeat(4, max-content);
    margin-top: layout.$bear-s;

    > .background {
        grid-column: 1 / -1;
        grid-row: 1;
        max-height: $product-description-min-height;
        // Min-height is important for IOS 14, to be able to render the Image, if it hasn't a predefined size
        min-height: $product-description-min-height;
    }

    > .headline {
        @include layout.adicta-headline;
        font-size: 20px;
        grid-column: 2 / span 12;
        grid-row: 2;
        margin-bottom: 0;
        margin-top: layout.$frog-s;
    }

    > .foreground {
        grid-column: 4 / span 8;
        grid-row: 3;
        margin-top: layout.$frog-s;
    }

    > .copy {
        @include layout.adicta-copy;
        grid-column: 2 / span 12;
        grid-row: 4;
        margin-bottom: 0;
        margin-top: layout.$frog-s;
        text-transform: none;
    }
}

@include layout.mqs-mq-medium {
    .product-description {
        $product-description-horizontal-space: 70px;
        grid-template-columns: 110px repeat(12, 1fr) 110px;
        grid-template-rows: auto;
        margin-top: layout.$blue-whale-l + layout.$bee-s;

        > .background {
            grid-row: 1 / span 3;
            max-height: 34.722vw;
            min-height: auto;
        }

        > .background > .responsive-picture {
            height: 100%;
        }

        > .headline {
            font-size: 30px;
            grid-column: 2 / span 7;
            grid-row: 4;
            margin-top: layout.$frog-l;
            padding-left: $product-description-horizontal-space;
        }

        > .foreground {
            grid-column: 10 / span 4;
            grid-row: 3 / span 5;
            margin-top: 0;
            max-height: 650px;
            padding-right: $product-description-horizontal-space;
        }

        > .copy {
            grid-column: 2 / span 7;
            grid-row: 5;
            margin-top: layout.$flamingo-s;
            padding-left: $product-description-horizontal-space;
        }
    }
}

@include layout.mqs-mq-max-content {
    .product-description {
        > .background {
            max-height: 500px;
        }
    }
}
