$t-rex-s: 90px;
$bear-s: 60px;
$flamingo-s: 45px;
$frog-s: 30px;
$slug-s: 20px;
$bee-s: 10px;
$ant-s: 5px;
$gnat-s: 0;

$godzilla-l: 165px;
$titan-l: 130px;
$blue-whale-l: 100px;
$t-rex-l: 90px;
$orca-l: 70px;
// $space-c-bear:      100px;
// $space-c-flamingo:  80px;
$frog-l: 45px;
$slug-l: 20px;
$bee-l: 15px;
// $space-c-ant:       0;
$gnat-l: 0;
