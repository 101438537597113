@use "../../layout/media-queries" as mqs;
@use "../../layout/spaces";
@use "../../layout/z-index";

.sticky-link-container {
    bottom: 0;
    height: 40px;
    position: fixed;
    width: 100%;
    z-index: z-index.$sticky-link-container;

    > .sticky-link-bar {
        grid-column: 1 / -1;
    }

    > .links {
        display: none;
    }
}

@include mqs.mq-medium {
    .sticky-link-container {
        height: 0;
        position: sticky;

        // @NOTE: Taken from design
        top: 539px;

        > .links {
            display: block;
            grid-column: 14 / span 1;

            > .bmc-sticky-link:nth-child(2) {
                margin-top: spaces.$bee-l;
            }
        }
    }
}
