@use "../../layout";

@media screen and (prefers-reduced-motion: reduce) {
    .scor-cta {
        display: block;
        font-size: 12px;

        margin-top: layout.$slug-s;
        padding: layout.$bee-l layout.$frog-s;

        text-align: center;
        text-decoration: none;
        text-transform: uppercase;
        transition: none;

        &.-mint {
            background-color: layout.$cruise;
            color: layout.$black;

            &:hover,
            &:focus {
                background-color: layout.$black;
                color: layout.$cruise;
            }
        }

        &.-white {
            background-color: layout.$white;
            color: layout.$black;

            &:hover,
            &:focus {
                background-color: layout.$black;
                color: layout.$white;
            }
        }

        &.-black {
            background-color: layout.$black;
            color: layout.$white;

            &:hover,
            &:focus {
                background-color: layout.$white;
                color: layout.$black;
            }
        }
    }
}

.scor-cta {
    display: block;
    font-family: layout.$main-font;
    font-size: 12px;

    margin-top: layout.$slug-s;
    padding: layout.$bee-l layout.$frog-s;

    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    transition: background-color 200ms ease-in-out, color 250ms ease-in;

    &.-mint {
        background-color: layout.$cruise;
        color: layout.$black;

        &:hover,
        &:focus {
            background-color: layout.$black;
            color: layout.$cruise;
        }
    }

    &.-white {
        background-color: layout.$white;
        color: layout.$black;

        &:hover,
        &:focus {
            background-color: layout.$black;
            color: layout.$white;
        }
    }

    &.-black {
        background-color: layout.$black;
        color: layout.$white;

        &:hover,
        &:focus {
            background-color: layout.$white;
            color: layout.$black;
        }
    }
}
