@use "../../layout";

.story {
    > .page-headline,
    > .ctawrapper {
        grid-column: 2 / span 12;
    }

    > .ctawrapper {
        grid-row: 5;
        margin-top: layout.$frog-s;
    }

    > .copy {
        grid-column: 2 / span 8;
        grid-row: 4;
        margin: 0;
    }

    > .copy {
        @include layout.rwb-text();
        font-size: 18px;
        margin-top: layout.$flamingo-s;
    }

    > .makeover-image-slider {
        grid-column: 1 / -1;
        margin-top: layout.$frog-s + 2px;
    }

    .makeover-image-slider > .wrapper {
        grid-column: 1 / -1;
    }

    > .makeover-image-slider .responsive-picture {
        max-height: 240px;
    }

    > .responsive-picture {
        grid-column: 1 / -1;
        margin-top: layout.$frog-s;
    }
}

@include layout.mqs-mq-medium {
    .story {
        grid-template-rows: auto;
        margin-top: layout.$blue-whale-l;

        > .page-headline,
        > .copy,
        > .ctawrapper {
            grid-column: 2 / span 3;
        }

        > .page-headline {
            font-size: 45px;
            grid-row: 1 / span 1;
            margin-top: 0;
        }

        > .copy {
            font-size: 20px;
            grid-row: 2 / span 1;
        }

        > .ctawrapper {
            grid-row: 3 / span 1;
        }

        > .responsive-picture:first-of-type {
            grid-column: 6 / -1;
            grid-row: 1 / span 4;
            margin-top: 0;
            max-height: 41rem;
        }

        > .responsive-picture:nth-of-type(2) {
            grid-column: 3 / span 6;
            grid-row: 5 / span 3;
            margin-top: layout.$t-rex-l;
            max-height: 23rem;
        }

        > .responsive-picture:nth-of-type(3) {
            grid-column: 10 / span 3;
            grid-row: 7 / span 3;
            max-height: 34.6875rem;
            position: relative;
        }

        > .responsive-picture:last-child {
            grid-column: 2 / span 12;
            grid-row: 9 / span 6;
            max-height: 56.625rem;
        }
    }
}
