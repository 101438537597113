$cruise: #b0eccc;
$black: #000000;
$cherry-pie: #2f014d;
$cape-cod: #404844;
$white: #ffffff;
$red-ribbon: #f01414;
$alto: #d8d8d8;
$nobel: #b3b3b3;

/* stylelint-disable plugin/stylelint-no-indistinguishable-colors */
@mixin wb-text {
    &.-white {
        color: $white;
    }

    &.-black {
        color: $black;
    }
}
