@use "../../layout";

.scor-support-component {
    align-items: center;
    font-family: layout.$main-font;

    grid-template-rows: minmax(auto, 99.688vw);
    justify-content: center;
    margin-top: layout.$bear-s;

    > .responsive-picture,
    > .content {
        grid-column: 1 / -1;
        grid-row: 1;
        height: 100%;
    }

    > .responsive-picture:nth-of-type(2),
    > .content {
        max-height: 80vw;
        z-index: 1;
    }

    > .content {
        align-items: center;
        justify-content: center;
        z-index: 2;

        > .headline {
            @include layout.scor-headline;
            font-size: layout.$frog-s;
            grid-column: 3 / span 10;
            grid-row: 1;
            text-align: center;
        }

        > .scor-cta {
            grid-column: 2 / span 12;
            grid-row: 2;
        }
    }
}

@include layout.mqs-mq-medium {
    .scor-support-component {
        grid-template-rows: minmax(auto, 34.722vw);

        > .responsive-picture:nth-of-type(1) {
            max-height: 34.722vw;
        }

        > .responsive-picture:nth-of-type(2),
        > .content {
            grid-column: 2 / span 12;
            max-height: 28.819vw;
        }

        > .content {
            > .headline {
                @include layout.scor-headline-m;
                font-size: 60px;
                grid-column: 4 / span 8;
            }

            > .scor-cta {
                grid-column: 6 / span 4;
            }
        }
    }
}

@include layout.mqs-mq-max-content {
    .scor-support-component {
        grid-template-rows: minmax(auto, 500px);

        > .responsive-picture:nth-of-type(1) {
            max-height: 500px;
        }

        > .responsive-picture:nth-of-type(2),
        > .content {
            max-height: 415px;
        }
    }
}
