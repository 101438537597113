@use "../../layout";

.bike-finder {
    margin-top: layout.$t-rex-s;
    > .content {
        background-color: layout.$red-ribbon;
        grid-column: 1 / -1;
        padding-bottom: layout.$t-rex-s;

        > .headline,
        > .ctacontainer {
            text-align: center;
        }

        > .headline {
            color: transparent;
            font-family: layout.$main-font;
            font-size: 60px;
            font-style: italic;
            font-weight: bold;
            grid-column: 4 / span 8;
            margin: 0;
            margin-top: layout.$orca-s;

            // SEE: https://developer.mozilla.org/en-US/docs/Web/CSS/-webkit-text-stroke
            // @NOTE: 1px in design but it looks better with 2 in the browser
            -webkit-text-stroke: 2px layout.$white;
            text-transform: uppercase;
            // tells firefox to take the grid-parent's width
            width: 100%;
        }

        > .ctacontainer {
            grid-column: 2 / span 12;
            text-align: center;
        }
    }
}

@include layout.mqs-mq-medium {
    .bike-finder {
        margin-top: layout.$blue-whale-l;

        > .content {
            grid-column: 2 / span 12;

            > .headline {
                font-size: 95px;
            }
        }
    }
}
