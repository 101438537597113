@use "../../layout";

.adicta-home-slider1 {
    > .background {
        grid-column: 1 / -1;
        grid-row: 1 / span 5;
    }

    > .foreground {
        grid-column: 3 / span 9;
        grid-row: 2 / span 5;
    }

    > .content-wrapper {
        grid-column: 3 / span 8;
        grid-row: 7;

        > .subheadline {
            @include layout.adicta-copy;
            @include layout.bw-text;
            font-size: 20px;
            margin-bottom: 0;
            margin-top: layout.$slug-s;
            text-transform: unset;
        }

        > .headline {
            @include layout.adicta-headline;
            @include layout.bw-text;
            font-size: 24px;
            margin: layout.$bee-s 0 0;
        }

        > .cta-wrapper {
            margin-top: layout.$frog-s;
        }

        > .cta-wrapper > .adicta-cta {
            grid-column: 1 / -1;
            margin-top: 0;
        }
    }
}

@include layout.mqs-mq-medium {
    .adicta-home-slider1 {
        grid-template-rows: repeat(5, 145px);

        > .background {
            grid-column: 1 / -1;
            grid-row: 1 / span 4;
            max-height: 580px;
        }

        .responsive-picture {
            height: 100%;
        }

        > .foreground {
            grid-column: 9 / span 3;
            grid-row: 2 / span 5;
            min-height: 480px;
        }

        > .content-wrapper {
            grid-column: 3 / span 6;
            grid-row: 2;

            > .subheadline {
                font-size: 30px;
                margin-top: 0;
            }

            > .headline {
                font-size: 70px;
                margin: 0;
            }

            > .cta-wrapper > .adicta-cta {
                grid-column: 1 / span 5;
            }
        }
    }
}
