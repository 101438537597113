$main-font: "Messina Sans";

// @NOTE: Currently only Messina Regular is used in the designs
// bold has been integrated just in case. Italic is in the font folder as I assume
// it'll need to be integrated as well.
@font-face {
    font-display: swap;
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-weight: 400;
    src: url("../assets/fonts/MessinaSans-Regular.woff2") format("woff2"),
        url("../assets/fonts/MessinaSans-Regular.woff") format("woff");
}

@font-face {
    font-display: swap;
    font-family: $main-font;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-style: normal;
    font-weight: 700;
    src: url("../assets/fonts/MessinaSans-Bold.woff2") format("woff2"),
        url("../assets/fonts/MessinaSans-Bold.woff") format("woff");
}
