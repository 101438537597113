@use "../../../layout";

.explore-list {
    background-color: layout.$white;
    display: grid;
    gap: 5px;

    grid-template-columns: repeat(12, 1fr);

    list-style: none;

    margin: 0;
    padding: 0;

    > .listitem {
        display: flex;
        grid-column: span 6;

        > .link {
            @include layout.linkcolor(layout.$white);
            align-items: center;

            display: flex;
            font-size: 30px;
            justify-content: center;

            position: relative;
        }
    }

    > .listitem > .link {
        > .image {
            max-height: 100%;

            object-fit: cover;
            width: 100%;
        }

        > .title {
            position: absolute;
        }
    }
}
