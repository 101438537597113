@use "../../../layout";

.icon-item {
    > .icon {
        display: flex;
        justify-content: center;
        margin-top: layout.$frog-s;
        max-height: 61px;
    }

    > .headline {
        display: flex;
        justify-content: center;
        margin-top: layout.$frog-s;
    }

    > .progress {
        display: flex;
        justify-content: center;
    }

    > .text {
        display: flex;
        font-size: 12px;
        justify-content: center;
    }
}
