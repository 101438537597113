@use "../../layout";

.adicta-home-slider2 {
    > .responsive-picture,
    > .content {
        grid-column: 1 / -1;
        grid-row: 1;
    }

    > .responsive-picture {
        min-height: 600px;
    }

    > .content {
        grid-template-rows: repeat(3, max-content);
        margin-top: layout.$orca-l;

        > .headline,
        > .subheadline {
            grid-column: 3 / span 10;
        }

        > .cta-wrapper {
            grid-column: 3 / span 8;
        }

        > .subheadline {
            @include layout.adicta-headline;
            @include layout.bw-text;
            font-size: 20px;
            font-weight: 400;
            margin: layout.$gnat-s;
        }

        > .headline {
            @include layout.adicta-headline;
            @include layout.bw-text;
            font-size: 35px;
            margin: layout.$gnat-s;
            margin-top: layout.$slug-s;
        }
    }

    &.-right {
        text-align: right;
        > .content > .headline,
        > .content > .subheadline {
            grid-column: 2 / span 11;
        }

        > .content > .cta-wrapper {
            grid-column: 5 / span 8;
        }
    }

    &.-center {
        text-align: center;
        > .content > .headline,
        > .content > .subheadline {
            grid-column: 2 / span 12;
        }

        > .content > .cta-wrapper {
            grid-column: 4 / span 8;
        }
    }
}

@include layout.mqs-mq-medium {
    .adicta-home-slider2 {
        align-items: center;
        justify-content: center;

        &,
        > .responsive-picture {
            max-height: 750px;
        }

        > .responsive-picture {
            grid-row: 1 / -1;
        }

        > .content {
            margin-top: layout.$gnat-l;
            > .subheadline {
                font-size: 30px;
                margin-top: layout.$gnat-s;
            }

            > .headline {
                font-size: 70px;
                margin-top: layout.$slug-l;
            }

            > .cta-wrapper {
                grid-column: 3 / span 3;
            }
        }

        &.-center {
            > .content > .headline,
            > .content > .subheadline {
                grid-column: 4 / span 8;
            }

            > .content > .cta-wrapper {
                grid-column: 7 / span 2;
            }
        }

        &.-right {
            > .content > .headline,
            > .content > .subheadline {
                grid-column: 7 / span 6;
            }

            > .content > .cta-wrapper {
                grid-column: 11 / span 2;
            }
        }
    }
}
