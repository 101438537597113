.bmc-mini-cart {
    margin-top: 0;
    position: relative;

    > .bmc-icon-link {
        > .badge {
            display: none;
            height: 12px;
            margin-left: -9px;
            margin-top: 3px;
            width: 12px;
        }

        > .badge > svg {
            height: 100%;
            width: 100%;
        }
    }

    &.-has-items > .bmc-icon-link > .badge {
        display: block;
    }
}
