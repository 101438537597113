@use "../../layout";

.listbox-collapsible {
    margin-top: 0;

    > .activeitem {
        $arrow-width: 24px;
        @include layout.unstyled-button;
        align-items: center;
        border: solid 1px layout.$mine-shaft;
        display: grid;
        font-family: layout.$main-font;
        font-size: 20px;
        font-weight: bold;
        grid-template-columns: 1fr 24px;
        padding: layout.$slug-s layout.$frog-s;
        width: 100%;

        > .name,
        > .arrowsvg {
            grid-row: 1;
        }

        > .name {
            grid-column: 1;
            margin-left: $arrow-width;
        }

        /* stylelint-disable a11y/media-prefers-reduced-motion */
        > .arrowsvg {
            grid-column: 2;
            height: 17px;

            transition: transform 150ms ease-in;
            width: $arrow-width;
        }
        /* stylelint-enable a11y/media-prefers-reduced-motion */

        > .arrowsvg.-is-open {
            transform: rotate(90deg);
        }
    }

    > .list {
        @include layout.unlist-a-list;
        display: none;
        font-family: layout.$main-font;
        font-size: 20px;
        margin-top: layout.$slug-s;
        text-align: center;
        text-transform: capitalize;

        &[aria-expanded="true"] {
            display: block;
        }

        > .option > .link {
            @include layout.linkcolor(layout.$mine-shaft);
            display: block;
            padding: layout.$bee-s 0;
            text-decoration: none;
            width: 100%;
        }
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .listbox-collapsible {
        > .activeitem > .arrowsvg {
            transition: none;
        }
    }
}
