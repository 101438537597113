@use "../../../layout";

.create-speed {
    overflow-x: hidden;

    > .row {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-around;
        margin-top: layout.$bee-s + layout.$ant-s;
        width: 100vw;

        > .speedsvg,
        > .createsvg {
            flex: 0 0 auto;
            height: 50px;
            margin-right: layout.$bee-s + layout.$ant-s;
        }

        .word {
            fill: none;
            stroke: layout.$red-ribbon;
            stroke-width: 1;
        }
    }

    > .row:nth-child(even),
    > .row:last-child {
        flex-direction: row-reverse;
    }

    > .row:last-child > svg {
        transform: translateX(20vw);
    }

    > .row:nth-child(2) > .createsvg:nth-child(3),
    > .row:nth-child(5) > .speedsvg:nth-child(4) {
        z-index: 15;
        > .word {
            fill: layout.$red-ribbon;
            stroke: none;
            stroke-width: 0;
        }
    }
}

@include layout.mqs-mq-medium {
    .create-speed {
        > .row {
            margin-top: layout.$slug-l;
            width: 100%;

            > .speedsvg,
            > .createsvg {
                flex: 0 0 auto;
                height: 100px;
                margin-right: layout.$slug-l;
            }
        }

        > .row:nth-child(2) > svg {
            transform: translateX(-15vw);
        }

        > .row:last-child > svg {
            transform: translateX(-5vw);
        }
    }
}

@include layout.mqs-mq-max-content {
    .create-speed {
        > .row:nth-child(2) > svg {
            transform: translateX(-250px);
        }

        > .row:last-child > svg {
            transform: translateX(-60px);
        }
    }
}
