@use "../../layout";

.page-header-scor {
    align-items: center;
    background-color: layout.$white;
    grid-template-rows: layout.$t-rex-s 1fr layout.$t-rex-s;
    height: layout.$t-rex-s;
    position: sticky;
    top: 0;
    z-index: layout.$sticky-navigation;

    > .menubutton,
    > .icons > .search,
    > .icons > .store-switcher-toggle {
        @include layout.unstyled-button;
    }

    > .icons > .store-switcher-toggle > .regionflag {
        height: 100%;
        margin-top: 3px;
        width: 24px;
    }

    > .menubutton {
        background-image: url("../../assets/navigation/menu.svg");
        background-repeat: no-repeat;
        display: block;
        grid-column: 2;
        grid-row: 1;
        height: 24px;
        padding: 1rem;
        padding: 0;
        text-align: left;
        width: 24px;

        &[aria-expanded="true"] {
            background-image: url("../../assets/navigation/close.svg");
        }
    }

    > .icons {
        align-items: flex-start;
        background-color: layout.$white;
        display: flex;
        grid-column: 1 / -1;
        grid-row: 3;
        height: 100%;

        // @TODO: Integrate the grid instead of using
        // padding
        padding-left: 20px;

        > .bmc-icon-link,
        > .search,
        > .bmc-mini-cart {
            margin-left: 24px;
        }

        // Don't show the checkout on mobile as it's
        // already in the first row
        > .bmc-mini-cart {
            display: none;
        }
    }

    > .scor-logo {
        grid-column: 7 / span 2;
        justify-self: center;
    }

    > .scor-logo + .bmc-mini-cart {
        grid-column: -3;
        justify-self: end;
    }

    > .navigation,
    > .search-container {
        background-color: layout.$white;
        grid-column: 1 / -1;
        grid-row: 2;
        overflow-y: scroll;
        padding-right: layout.$slug-s;
        position: relative;
    }

    > .search-container {
        padding-right: 0;
    }

    // @TODO: Due to the inconsistent designs we need to clean
    // this logic up at a later point regarding the icons
    /* stylelint-disable-next-line selector-class-pattern */
    > .navigation._hidden ~ .icons {
        display: none;
    }

    > .search-container.-shown ~ .icons {
        display: flex;
    }
}

@include layout.mqs-mq-medium {
    .page-header-scor {
        align-items: center;
        grid-template-rows: layout.$t-rex-l auto;
        height: layout.$t-rex-l;

        > .scor-logo + .bmc-mini-cart {
            display: none;
        }

        > .navigation {
            padding-bottom: layout.$frog-l;
        }

        > .navigation > .search-container {
            height: auto;
            min-height: 636px;
            overflow-y: auto;
            padding-right: 0;
        }

        > .navigation,
        > .search-container {
            height: auto;
            overflow-y: unset;
        }

        /* stylelint-disable-next-line selector-class-pattern */
        > .navigation._hidden ~ .icons {
            display: flex;
        }

        > .icons {
            align-items: center;
            grid-column: 10 / span 4;
            grid-row: 1;
            justify-content: end;

            // @TODO: Fix the size of the cart icon
            > .bmc-mini-cart {
                display: block;
                margin-top: 2px;
            }
        }

        > .icons > .search,
        > .store-switcher-toggle {
            margin-top: 3px;
        }

        > .scor-logo {
            grid-column: 7 / span 2;
            justify-self: center;
        }

        > .navigation > .menu-list > .level {
            grid-row: 1 / span 2;
            height: auto;
        }
    }
}
