@use "../../../layout";

.list {
    list-style: none;

    margin: 0;
    padding: 0;
    text-transform: uppercase;

    > .headline {
        font-weight: bold;
    }
}

@include layout.mqs-mq-medium {
    .list {
        border-top: none;

        &:nth-of-type(2) {
            border-bottom: none;
        }
    }
}
