@use "../../../../layout";

.expandable-list-item {
    cursor: pointer;
    font-size: 15px;

    // @TODO: Convert this to padding so the user can click without hitting the next element
    margin-top: layout.$slug-s;

    > .label,
    > .menu-group {
        margin-left: layout.$bee-l;
    }

    > .menu-group {
        display: none;
    }

    > .label {
        align-items: center;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        > .arrowsvg {
            height: 13px;
            margin-right: layout.$ant-s;

            transition-duration: 0.2s;
            width: 7px;

            will-change: transform;
        }
    }

    &[aria-expanded="true"] {
        > .menu-group {
            display: block;
        }

        > .label > .arrowsvg {
            transform: rotate(90deg);
        }
    }
}
