@use "../../layout/_helpers";

.bmc-icon-link {
    align-items: center;

    display: flex;
    margin-top: 0;

    &:not(.-show-label) {
        > .label {
            @include helpers.visually-hidden;
        }
    }
}
