@use "../../layout";

.adicta-category-profile-header {
    grid-template-rows: repeat(5, auto);
    margin-top: 0;
    overflow: hidden;

    > .responsive-picture:first-child {
        grid-column: 1 / -1;
        grid-row: 1 / span 2;
        min-height: 190px;
    }

    > .responsive-picture:nth-child(2) {
        grid-column: 3 / span 5;
        grid-row: 2 / span 3;
    }

    // @NOTE: Disable this until they fix the plugin's autofix
    /* stylelint-disable a11y/media-prefers-reduced-motion */
    .slideright-appear {
        transform: translate(calc(-100% - 126px));
    }

    .slideright-portrait-appear {
        transform: translate(calc(-100% - 155px));
    }

    .slideright-appear-done,
    .slideright-portrait-appear-done {
        transform: none;
        transition: transform 600ms ease-in-out;
    }

    .fade-appear {
        opacity: 0.01;
    }

    .fade-appear-done {
        opacity: 1;
        transition: opacity 300ms ease-in;
    }

    > .content {
        grid-column: 3 / span 10;

        > .headline,
        > .subheadline,
        > .copy {
            margin: 0;
        }

        > .headline,
        > .subheadline {
            @include layout.adicta-headline;
        }

        > .headline {
            font-size: 35px;
        }

        > .subheadline {
            font-size: 20px;
            margin-top: layout.$frog-s;
        }

        > .copy {
            font-family: layout.$main-font;
            font-size: 15px;
            line-height: 25px;
            margin-top: layout.$slug-s;
        }
    }
}

@include layout.mqs-mq-medium {
    .adicta-category-profile-header {
        // @NOTE: Design forgot this in the PDF
        > .responsive-picture:first-child {
            grid-column: 2 / span 9;
            grid-row: 1 / span 2;
        }

        > .responsive-picture:nth-child(2) {
            grid-column: 3 / span 3;
            grid-row: 2 / span 3;
            max-height: 397px;
        }

        > .content {
            display: grid;
            grid-column: 7 / span 6;
            grid-template-columns: repeat(6, 1fr);
            grid-template-rows: auto;

            > .headline,
            > .subheadline {
                grid-column: 1 / -1;
            }

            > .headline {
                @include layout.adicta-headline-m;
                font-size: 70px;
            }

            > .subheadline {
                font-size: 30px;
                font-weight: normal;
                margin-top: layout.$orca-l;
            }

            > .copy {
                font-size: 16px;
                grid-column: 1 / span 4;
            }
        }
    }
}

@media (prefers-reduced-motion: reduce) {
    .adicta-category-profile-header {
        .slideright-appear,
        .slideright-portrait-appear {
            transform: none;
        }

        .fade-appear {
            opacity: 1;
        }
    }
}
