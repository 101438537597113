@use "../../layout";

$triangle-background: url("../../assets/filter/triangles_black.png")
    layout.$mine-shaft;

.adicta-footer {
    background: $triangle-background;
    background-position: unset;
    background-repeat: no-repeat;
    background-size: cover;

    color: layout.$white;

    font-family: layout.$main-font;
    font-size: 13px;
    isolation: isolate;

    margin-top: layout.$bear-s;

    > .top {
        padding-top: layout.$frog-s;
    }

    > .top > .social-wrapper {
        border-bottom: 1px solid layout.$white;
        grid-column: 2 / span 12;
        padding-bottom: layout.$slug-s;
    }

    > .top > .social-wrapper > .headline {
        display: block;
        letter-spacing: 2px;
    }

    > .top > .social-wrapper > .social-icons,
    > .top > .social-wrapper > .newsletter-wrapper {
        margin-top: layout.$slug-s;
    }

    > .top > .social-wrapper > .social-icons {
        display: flex;
        grid-column: 1 / span 4;
        grid-row: 2;
        justify-content: space-between;
    }

    > .top > .social-wrapper > .headline {
        font-weight: bold;
        grid-row: 1;
        text-transform: uppercase;
    }

    > .top > .social-wrapper > .headline:nth-of-type(1) {
        grid-column: 1 / span 6;
    }

    > .top > .social-wrapper > .headline:nth-of-type(3) {
        grid-column: 7 / span 6;
    }

    > .top > .social-wrapper > .newsletter-wrapper {
        display: flex;
        grid-column: 7 / span 6;
        grid-row: 2;
    }

    /* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
    > .top > .social-wrapper > .newsletter-wrapper > .signup-form {
        border: none;

        > .input {
            border: 1px solid layout.$white;
            font-size: 10px;
            padding: 3px layout.$ant-s;
        }

        > .input::placeholder,
        > .submit {
            letter-spacing: 2px;
            text-transform: uppercase;
        }

        > .submit {
            font-size: 10px;
            margin-top: layout.$ant-s;
            padding: layout.$gnat-s layout.$bee-s;
            text-decoration: underline;
        }
    }
    /* stylelint-enable max-nesting-depth, selector-max-compound-selectors */

    > .content > .list {
        align-self: flex-start;
        padding-bottom: layout.$frog-s;
        padding-top: layout.$frog-s;
    }

    > .content > .list:nth-of-type(1) {
        grid-column: 2 / span 13;
        grid-row: 1;
    }

    > .content > .list:nth-of-type(2) {
        grid-column: 9 / span 6;
        grid-row: 1;
    }

    > .content > .list:nth-of-type(3) {
        border-top: 1px solid layout.$white;
        grid-column: 2 / span 7;
        grid-row: 2;
    }

    > .content > .list:nth-of-type(4) {
        border-top: 1px solid layout.$white;
        grid-column: 9 / span 5;
        grid-row: 2;
    }

    > .content > .ftp {
        grid-column: 12 / span 2;
        grid-row: 3;
        margin-top: layout.$godzilla-l;
    }

    > .bottom > .logo {
        grid-column: 2 / span 1;
        grid-row: 1;

        /* stylelint-disable-next-line selector-class-pattern */
        &.-adicta path {
            fill: layout.$white;
        }
    }

    > .bottom > .copyright {
        grid-column: 2 / span 6;
        grid-row: 2;
        margin-top: layout.$flamingo-s;
    }

    > .bottom > .legals {
        grid-column: 2 / span 12;
        grid-row: 3;
        margin-top: layout.$flamingo-s;
    }

    /* stylelint-disable max-nesting-depth, selector-max-compound-selectors  */
    > .bottom > .legals > .legal-list {
        display: flex;
        justify-content: space-between;
        list-style: none;
        padding: layout.$gnat-s;

        > .list-item {
            margin-top: unset;
        }
    }
    /* stylelint-enable max-nesting-depth, selector-max-compound-selectors */
}

@include layout.mqs-mq-medium {
    .adicta-footer {
        background-position: center;

        margin-top: layout.$blue-whale-l;
        padding-bottom: layout.$bee-s;
        padding-top: layout.$orca-l;

        > .top {
            padding-bottom: layout.$slug-s;
            padding-top: unset;
        }

        > .top > .social-wrapper > .headline:nth-of-type(1) {
            display: none;
        }

        > .top > .social-wrapper > .social-icons {
            display: flex;
            grid-column: 1 / span 1;
            justify-content: space-between;
        }

        > .top > .social-wrapper > .newsletter-wrapper {
            align-items: center;
            display: flex;
            font-size: 13px;
            grid-column: 5 / span 4;
            grid-row: 2;
        }

        > .top > .social-wrapper > .headline:nth-of-type(3) {
            align-self: center;
            font-weight: bold;
            grid-column: 3 / span 2;
            grid-row: 2;
            padding-top: layout.$slug-s;
            text-transform: uppercase;
        }

        /* stylelint-disable max-nesting-depth, selector-max-compound-selectors */
        > .top > .social-wrapper > .newsletter-wrapper > .signup-form {
            > .input,
            > .submit {
                font-size: 13px;
            }
        }
        /* stylelint-enable max-nesting-depth, selector-max-compound-selectors */

        > .content {
            align-items: center;
        }

        > .content > .list {
            align-self: flex-start;
            grid-row: 1;
        }

        > .content > .list:nth-of-type(1) {
            grid-column: 2 / span 2;
        }

        > .content > .list:nth-of-type(2) {
            grid-column: 4 / span 2;
        }

        > .content > .list:nth-of-type(3) {
            border-top: none;
            grid-column: 6 / span 2;
            grid-row: 1;
        }

        > .content > .list:nth-of-type(4) {
            border-top: none;
            grid-column: 8 / span 2;
            grid-row: 1;
        }

        > .content > .ftp {
            grid-column: 11 / span 3;
            grid-row: 1;
            margin-top: unset;
        }

        > .bottom {
            align-items: center;
            margin-top: layout.$t-rex-l;
        }

        > .bottom > .logo {
            align-self: flex-end;
            grid-column: 2 / span 2;
            grid-row: 1;
        }

        > .bottom > .copyright {
            grid-column: 4 / span 6;
            grid-row: 1;
        }

        > .bottom > .legals {
            grid-column: 11 / span 3;
            grid-row: 1;
            justify-content: flex-start;
        }
    }
}
