@use "../../layout";

.highlight-carousel {
    > .headline,
    > .subheadline {
        grid-column: 2 / span 11;
    }

    > .headline {
        @include layout.adicta-headline;
        @include layout.adicta-headline-m;
        font-size: 40px;
        margin-top: 24px;
    }

    > .subheadline {
        @include layout.adicta-headline;
        font-size: 20px;
        text-align: center;
    }

    > .content-slider {
        grid-column: 1 / -1;
        margin-top: layout.$frog-s;

        .splide {
            max-width: 350px;
        }

        // Slider Center Mode
        // stylelint-disable selector-class-pattern
        .splide__track {
            overflow: visible;
            transform: translateX(40px);
        }

        .splide__arrow {
            // Define a light background color on the arrows for mobile view only, due that the arrows are located on the white gap between the slides.
            background-color: rgba(0 0 0 / 0.5);
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@include layout.mqs-mq-medium {
    .highlight-carousel {
        > .headline {
            font-size: 90px;
            margin-bottom: 40px;
        }

        > .subheadline {
            font-size: 30px;
        }

        > .content-slider {
            .splide__track {
                transform: translateX(200px);
            }

            .splide__arrow {
                background-color: unset;
            }
        }
    }
}
