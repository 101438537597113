@use "colors";

// @TODO: Check https://css-tricks.com/4-ways-to-animate-the-color-of-a-text-link-on-hover/
@mixin gradient-right {
    $speed: 0.3s;
    background-image: linear-gradient(currentColor, currentColor);
    background-repeat: no-repeat;

    // @TODO: Hacky solution for animating the button without the overlapping 1px border - rework this
    background-size: 0 100%;
    color: currentColor;

    transition: background $speed ease;
    @media screen and (prefers-reduced-motion: reduce) {
        > .text {
            color: currentColor;
            transition: none;
        }
    }

    > .text {
        color: currentColor;
        transition: color ease-in-out $speed;
    }

    &:hover,
    &:focus {
        background-size: 100% 100%;

        > .text {
            color: colors.$white;
        }
    }
}

// Simple visibility animation.
// P.S. main usage is in BMC HighlightCarousel for the Hover effect
@keyframes visibility {
    100% {
        opacity: 0.6;
    }
}
