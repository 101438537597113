@use "../../layout";
@mixin col-offset {
    left: layout.$flamingo-s;
    position: relative;
}
.teaser-product-story {
    $row-height: 12vw;
    column-gap: 5px;
    grid-auto-rows: $row-height;
    margin-top: layout.$frog-s;
    position: relative;
    > .frame-container {
        display: grid;
        grid-auto-rows: $row-height;
        grid-column: 1 / -1;
        grid-row: 1 / span 11;
        grid-template-columns: repeat(12, 1fr);
        > .frame-headline {
            @include layout.rwb-text();
            font-size: layout.$frog-s;
            font-weight: bold;
            grid-column: 3 / span 10;
            grid-row: 2 / span 3;
            margin: 0;
            max-width: 250px;
        }
        > .responsive-picture {
            grid-column: 2 / span 12;
            grid-row: 4 / span 8;
            width: 100%;
        }
        > .responsive-picture > .fallback {
            height: unset;
        }
    }

    > .copy-container {
        box-sizing: border-box;
        grid-column: 3 / span 10;
        grid-row: 14;
        position: absolute;
        right: 0;
        > .copy {
            @include layout.rwb-text();
            font-size: layout.$slug-s;
            font-weight: bold;
            margin: 0;
        }
    }
    > .responsive-picture {
        &:nth-of-type(1) {
            grid-column: 3 / span 12;
            grid-row: 9 / span 14;
            .fallback {
                max-height: 58vw;
            }
        }
        &:nth-of-type(2) {
            grid-column: 8 / span 6;
            grid-row: 25 / span 5;
            .fallback {
                bottom: 9.5vw;
                max-height: 75vw;
                position: relative;
            }
        }
        &:nth-of-type(3) {
            grid-column: 1 / span 12;
            grid-row: 19 / span 5;
            .fallback {
                max-height: 50vw;
            }
        }
        > .fallback {
            height: unset;
        }
    }
}

@include layout.mqs-mq-medium {
    @media screen and (prefers-reduced-motion: reduce) {
        > .responsive-picture:nth-of-type(2),
        > .responsive-picture:nth-of-type(3) {
            transform: scale(1);
            transition: none;
        }
    }

    .teaser-product-story {
        grid-auto-rows: 50px;
        margin-top: layout.$godzilla-l;

        > .frame-container.grid {
            column-gap: 5px;
            display: grid;
            grid-auto-rows: minmax(auto, auto);
            grid-column: 1 / span 10;
            grid-row: 1 / span 13;
            grid-template-columns: 40px repeat(9, 1fr);
            > .frame-headline {
                @include layout.rwb-text();
                @include col-offset;
                font-size: layout.$flamingo-s;
                grid-column: 2 / span 8;
                grid-row: 1;
                margin-top: layout.$flamingo-l;
                max-width: 350px;
            }
            > .responsive-picture {
                @include col-offset;
                grid-column: 2 / span 6;
                grid-row: 2;
            }
            > .responsive-picture > img {
                object-fit: contain;
            }
        }

        > .copy-container {
            @include col-offset;
            grid-column: 2 / span 3;
            grid-row: 15;
            padding-right: unset;
            width: 100%;
        }
        > .responsive-picture:nth-of-type(1) {
            grid-column: 9 / span 6;
            grid-row: 2 / span 10;

            > .fallback {
                height: 100%;
                max-height: 400px;
            }
        }
        > .responsive-picture:nth-of-type(2) {
            @include col-offset;
            grid-column: 11 / span 2;
            grid-row: 11 / span 9;
            > .fallback {
                bottom: 0;
                max-height: 310px;
            }
        }
        > .responsive-picture:nth-of-type(3) {
            grid-column: 6 / span 4;
            grid-row: 15;
            > .fallback {
                max-height: 276px;
            }
        }

        // stylelint-disable a11y/media-prefers-reduced-motion
        > .responsive-picture:nth-of-type(2),
        > .responsive-picture:nth-of-type(3) {
            transform: scale(1);
            transition: transform 1s cubic-bezier(0.52, 0.16, 0.38, 1.2);
        }

        &.-in-view {
            > .responsive-picture:nth-of-type(2),
            > .responsive-picture:nth-of-type(3) {
                transform: scale(1.3);
            }
        }
    }
}
