@use "../../layout";

.scor-product-teaser {
    background-color: layout.$alto;
    margin-top: 0;
    overflow-x: hidden;
    padding-bottom: layout.$bear-s * 1.7;
    width: 100%;

    > .content {
        > .productheadline {
            font-size: 20px;
            margin: 0;
            margin-top: layout.$frog-s;
            text-align: center;
            text-transform: uppercase;
        }

        > .ctalist,
        > .productheadline {
            grid-column: 2 / span 12;
        }

        > .ctalist {
            @include layout.unlist-a-list();
            display: flex;
            flex-direction: column;
            margin-top: layout.$ant-s;
        }

        // @NOTE: BMC requested inconsistent margin here
        /* stylelint-disable selector-max-compound-selectors */
        > .ctalist > .item > .scor-cta {
            margin-top: layout.$bee-s;
        }
        /* stylelint-enable selector-max-compound-selectors */

        > .splide-container {
            grid-column: 1 / -1;
            margin-top: layout.$frog-s * 2.5;
            max-width: 100%;
            transform: scale(1.2) translateX(-1vw);
        }
    }
}

@include layout.mqs-mq-medium {
    .scor-product-teaser {
        padding-bottom: layout.$t-rex-l;

        > .content {
            > .productheadline {
                font-size: 40px;
                margin-top: layout.$orca-l;
                text-align: center;
                text-transform: uppercase;
            }

            > .ctalist,
            > .productheadline {
                grid-column: 5 / span 6;
            }

            > .ctalist {
                @include layout.unlist-a-list();
                column-gap: 12px;
                display: grid;
                grid-template-columns: repeat(6, 1fr);
            }

            > .ctalist > .item {
                grid-column: span 3;
            }

            > .splide-container {
                grid-column: 5 / span 6;
                margin-top: layout.$flamingo-s;
                transform: unset;
            }
        }
    }
}
