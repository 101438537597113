@use "../../layout";

.notification {
    $length: 16px;
    align-items: center;
    background-color: layout.$white;
    border: 2px solid currentColor;
    border-radius: 50%;

    color: layout.$red-ribbon;

    display: flex;

    font-family: layout.$main-font;
    font-size: 14px;
    font-weight: bold;
    height: $length;
    justify-content: center;
    margin: 0;
    margin-top: 0;

    width: $length;
}
