// Red & Iron have 3.47: needs 4.5
// Red-Ribbon & White have 4.36: needs 4.5
// Red-Ribbon on Black: 4.82 ✅
$black: #000000;
$east-side: #b38bc0;
$gull-gray: #a0b5bc;
$gull-gray: #a0b5bc;
$fuscous-gray: #424241;
$kangaroo: #cccec3;
$mine-shaft: #353535;
$mischka: #e5e4e6;
$silver-chalice: #aaaaaa;
$red-ribbon: #f01414;
$white: #ffffff;
$dusty-gray: #979797;
/* stylelint-disable plugin/stylelint-no-indistinguishable-colors */
@mixin rwb-text {
    &.-red {
        color: $red-ribbon;
    }

    &.-white {
        color: $white;
    }

    &.-black {
        color: $black;
    }
}

:root {
    --primary-color: #{$red-ribbon};
    --primary-cta-text-color: #{$white};
}
/* stylelint-enable plugin/stylelint-no-indistinguishable-colors */
