@use "./spaces";
@use "./colors";
@use "../../../layout/media-queries" as mqs;
/* !important needs to be used to make changes global otherwise it will be overwritten by magentos pagebuilder */
.scor-slick-slider {
    display: grid;
    .slick-dots {
        > .slick-active > button::before {
            display: none;
        }
        > .slick-active > button::after {
            background: colors.$cruise !important;
            width: 100% !important;
        }
        > li {
            height: spaces.$bee-l !important;
            width: 15vw !important;
        }
        > li > button {
            background: transparent !important;
            border: none !important;
            border-radius: unset !important;
            height: 100% !important;
            padding: unset !important;
            width: 100% !important;
        }
        > li > button::before {
            display: none;
        }
        > li > button::after {
            background: colors.$alto !important;
            content: "";
            display: block !important;
            height: 4px !important;
            position: absolute;
            top: 0;
            width: 100%;
        }
    }
}

@include mqs.mq-medium {
    .scor-slick-slider {
        .slick-dots {
            bottom: spaces.$slug-l !important;
        }
    }
}
