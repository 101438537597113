@use "../../../layout";

.menu-list {
    background-color: layout.$white;
    font-family: layout.$main-font;
    grid-template-rows: min-content;

    // @NOTE: Taken from design

    height: 634px;
    margin-top: layout.$frog-s;

    > .level {
        grid-row: 1 / -1;

        height: 100%;
        z-index: 1;

        &.-with-border {
            border-left: solid layout.$mine-shaft 1px;
        }

        &:first-child {
            font-size: 22px;
            grid-column: 1 / span 3;
            position: relative;
        }

        &:nth-child(2) {
            grid-column: 4 / span 3;
        }

        &:nth-child(3) {
            grid-column: 7 / span 3;
        }
    }

    // First column should be bold
    > .level > [data-parent="root"] {
        font-weight: bold;
    }

    > .explorecontainer {
        display: grid;

        grid-column: 3 / -1;
        grid-row: 1 / -1;
        visibility: visible;
        z-index: 10;

        > .explore-list {
            grid-template-rows: repeat(2, minmax(0, 266px));
        }

        > .explore-list > .listitem {
            overflow: hidden;
        }

        &.hidden {
            visibility: hidden;
        }
    }

    > .preview {
        grid-column: 10 / span 3;
        grid-row: 1 / span 2;

        > img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        &.-product > img {
            height: auto;
        }
    }
}

@include layout.mqs-mq-large {
    .menu-list {
        > .level {
            grid-column: span 2;
            grid-row: 1 / -1;

            height: 100%;
            z-index: 1;

            &.-with-border {
                border-left: solid layout.$mine-shaft 1px;
            }

            &:first-child {
                font-size: 22px;
                grid-column: 1 / span 2;

                position: relative;
            }

            &:nth-child(2) {
                grid-column: 3 / span 2;
            }

            &:nth-child(3) {
                grid-column: 5 / span 2;
            }
        }
    }

    > .preview {
        grid-column: 10 / span 3;
        grid-row: 1 / span 2;

        > img {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }

        &.-product > img {
            height: auto;
        }
    }
}
