@use "../../layout";

$mood-board-background-max-height: 775px;

.mood-board {
    align-items: center;
    justify-items: center;
    margin-top: layout.$flamingo-s;

    > .copy {
        @include layout.adicta-headline;
        color: layout.$black;
        font-size: 20px;
        grid-column: 2 / span 12;
        text-align: center;
    }

    > .image-slider {
        grid-column: 1 / -1;
        margin-bottom: layout.$gnat-s;
        margin-top: layout.$flamingo-s;

        // stylelint-disable selector-class-pattern
        .splide__arrow {
            background: unset;
        }

        .splide__arrow > svg {
            fill: layout.$white;
        }
    }
}

@include layout.mqs-mq-medium {
    .mood-board {
        grid-template-rows: auto;
        isolation: isolate;
        margin-top: layout.$blue-whale-l;

        > .copy {
            font-size: 30px;
            grid-column: 3 / span 10;
            grid-row: 1;
            margin: 0;
        }

        > .background {
            grid-column: 1 / -1;
            grid-row: 4 / span 4;
            margin: 0;
            width: 100%;

            > .responsive-picture {
                display: grid;
                grid-template-columns: repeat(12, 1fr);
                grid-template-rows: minmax(
                    auto,
                    $mood-board-background-max-height
                );
            }

            > .responsive-picture > .fallback {
                grid-column: 1 / -1;
                grid-row: 1;
            }
        }

        > .left-image {
            display: block;
            grid-column: 3 / span 4;
            grid-row: 2;
            margin-top: layout.$t-rex-l;
            z-index: 1;
        }

        > .right-image {
            display: block;
            grid-column: 8 / span 5;
            grid-row: 3 / span 2;
            z-index: 2;
        }
    }
}
