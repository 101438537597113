@use "../../../layout/fonts";

@mixin adicta-headline {
    font-family: fonts.$main-font;
    font-weight: 700;

    // @NOTE: Not sure if this is what design meant with "3"
    // but it looks correct.
    letter-spacing: 3px;
    text-transform: uppercase;
}

@mixin adicta-headline-m {
    letter-spacing: 5px;
}

@mixin adicta-copy {
    font-family: fonts.$main-font;
    font-weight: 400;
    letter-spacing: 3px;
    text-transform: uppercase;
}
