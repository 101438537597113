.responsive-picture {
    display: block;
    /* @todo: Find out the Bug ticket, where the IOS 14.7 Bug has been fixed up IOS 15, and improve this Workaround, because
         sometime if responsive Picture didn't has a min-height, then it will not render its full height correctly */
    height: 100%;

    > .fallback {
        height: 100%;
        object-fit: cover;
        width: 100%;
    }
}
