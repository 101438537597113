$breakpoint-small: 768px;
$breakpoint-medium: 1280px;
$max-content-width: 1440px;

:root {
    --mq-small: #{$breakpoint-small - 1};
}

@mixin mq-small {
    @media (max-width: #{$breakpoint-small - 1}) {
        @content;
    }
}

@mixin mq-medium {
    @media (min-width: $breakpoint-small) {
        @content;
    }
}

@mixin mq-large {
    @media (min-width: $breakpoint-medium) {
        @content;
    }
}

@mixin mq-max-content {
    @media (min-width: $max-content-width) {
        @content;
    }
}
