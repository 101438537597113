@use "../../layout";

.content-slider {
    margin-top: 0;
    position: relative;

    > .wrapper {
        grid-column: 1 / -1;
        // Fix firefox problem where it displays the full width of slider, and makes the page horizontally scrollable.
        overflow: hidden;
    }

    .splide {
        // @TODO: max-width shouldn't be set here
        //@note splide renders the parent container bigger than the actual slide by some small breakpoints
        max-width: 335px;
        position: initial;

        svg {
            fill: layout.$white;
        }

        &__arrow {
            background: transparent;
            // Override magento border-style on buttons.
            border: unset;
            top: 20%;
            transform: unset;
        }
    }
}
