@use "../../layout";

$care-instructions-initial-max-height: 500px;
$care-instructions-initial-desktop-max-height: 365px;

.care-instructions {
    background-color: layout.$black;
    background-image: url("../../assets/filter/care-instructions.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    margin: layout.$bear-s layout.$slug-l 0;
    padding: layout.$orca-l 0;
    position: relative;

    > .background-wrapper {
        background: layout.$black;
        display: grid;
        grid-column: 2 / -2;
        grid-row: 1;
        grid-template-columns: repeat(12, 1fr);
        grid-template-rows: minmax($care-instructions-initial-max-height, auto);
        z-index: 1;

        .image {
            display: inline-block;
            grid-column: 1 / -1;
            grid-row: 1 / -1;
            height: 100%;
            max-width: 100%;
            object-fit: cover;
            width: 100%;
            z-index: -1;
        }
    }

    > .content-wrapper {
        // align its children
        align-items: center;
        // align this container to its parent
        align-self: center;
        color: layout.$white;
        display: block;
        grid-column: 3 / -3;
        grid-row: 1;
        justify-items: center;
        text-align: center;
        z-index: 2;

        > .headline {
            @include layout.adicta-headline;
            color: layout.$white;
            font-size: 24px;
            margin: 0;
        }

        > .icon {
            height: 100px;
            margin-top: layout.$frog-l;
            width: 115px;
        }

        > .expand-content-btn {
            color: layout.$white;
            cursor: pointer;
            margin-top: layout.$orca-l;
        }

        > .expanded-content {
            margin-top: layout.$flamingo-s;
            z-index: 3;
        }

        > .expanded-content .expand-content-headline {
            @include layout.adicta-headline;
            color: layout.$white;
            font-size: 20px;
            margin: 0;
        }

        > .expanded-content .expand-content-copy {
            @include layout.adicta-copy;
            color: layout.$white;
            font-size: 13px;
            margin-top: layout.$flamingo-s;
            padding-bottom: layout.$flamingo-s;
            text-align: left;
        }

        // reset standard marker of the list.
        ul {
            list-style: url("../../assets/svgs/care-instructions-arrow.svg");
            margin: 13px 0;
            padding-left: 40px;
        }

        // spacing between the List-Items.
        li + li {
            margin-top: layout.$bee-s;
        }
    }

    .expanded-content .close {
        cursor: pointer;
        height: layout.$orca-l;
        position: absolute;
        right: layout.$flamingo-s;
        top: layout.$flamingo-s;
        width: layout.$orca-l;
    }
}

// Expanded situation
.care-instructions.-expanded {
    grid-template-rows: minmax($care-instructions-initial-max-height, 1fr);
    padding: 0;

    > .background-wrapper {
        .image {
            grid-row: unset;
        }
    }

    > .content-wrapper {
        display: block;
        grid-row: 1 / -1;
        padding-top: layout.$bear-s;
    }
}

@include layout.mqs-mq-medium {
    .care-instructions {
        // 110px top margin - Design
        margin: layout.$blue-whale-l + layout.$bee-s layout.$flamingo-s 0;
        min-height: $care-instructions-initial-desktop-max-height;
        padding: layout.$slug-s 0;

        > .background-wrapper {
            grid-template-rows: minmax(
                auto,
                $care-instructions-initial-desktop-max-height
            );
            min-height: inherit;
        }

        > .content-wrapper {
            display: grid;
            grid-column: 5 / -5;

            > .headline {
                font-size: 30px;
                grid-column: 1 / -1;
            }

            > .icon {
                grid-column: 1 / -1;
            }

            > .expanded-content .expand-content-headline {
                font-size: 30px;
            }

            > .expanded-content .expand-content-copy {
                font-size: 16px;
            }

            > .expand-content-btn {
                grid-column: 5 / -5;
                width: 100%;
            }

            > .expanded-content .close {
                right: layout.$blue-whale-l;
                top: layout.$frog-l;
            }
        }
    }

    // Expanded situation
    .care-instructions.-expanded {
        > .background-wrapper {
            grid-template-rows: minmax(
                $care-instructions-initial-desktop-max-height,
                50vh
            );

            .image {
                grid-row: 1 / -1;
            }
        }

        > .content-wrapper {
            padding-top: 0;
        }
    }
}
