@use "../../layout";
@use "../../layout/colors";
@use "../../layout/fonts";
@use "../../layout/spaces";

.brand-teaser {
    max-height: 320px;
    position: relative;
    &.-headline {
        align-items: center;
        display: flex;
        font-family: fonts.$headline-font;
        font-size: spaces.$frog-s;
        overflow: hidden;
        text-transform: uppercase;
        word-break: break-word;
        > .brand-teaser-headline-wrapper {
            align-items: center;
            background-color: colors.$white;
            display: flex;
            height: 100%;
            justify-content: center;
            mix-blend-mode: screen;
            position: absolute;
            width: 100%;
        }
        > .brand-teaser-headline-wrapper > .brand-teaser-headline {
            padding: 0 spaces.$slug-s;
            text-align: center;
        }
    }
}

@include layout.mqs-mq-medium {
    .brand-teaser {
        max-height: 855px;
        &.-headline {
            font-size: spaces.$frog-l;
        }
    }
}

@include layout.mqs-mq-large {
    .brand-teaser {
        &.-headline {
            font-size: spaces.$orca-l;
        }
    }
}
