@use "../../layout";

.text-module {
    @include layout.rwb-text();
    font-family: layout.$main-font;
    font-size: layout.$slug-s + 5px;
    font-weight: bold;
    grid-column: 2 / span 12;
    margin-top: layout.$frog-s;
    padding: 0;

    &.-center {
        text-align: center;
    }

    &.-left {
        text-align: left;
    }

    > .headline {
        color: currentColor;
        font-family: layout.$main-font;
        font-size: layout.$slug-s + 5px;
        font-weight: bold;
        text-transform: none;
    }

    > .content {
        margin-top: layout.$flamingo-s;
    }
}

@include layout.mqs-mq-medium {
    .text-module {
        font-size: layout.$frog-l;
        margin-top: layout.$t-rex-l;
        > .content {
            margin-top: layout.$bear-s;
        }
        .headline {
            font-size: layout.$frog-l;
        }
    }
}
