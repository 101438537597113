@use "../../layout";

$full-width-image-height: 250px;
.adicta-full-width-image {
    margin-top: layout.$bear-s;

    > .responsive-picture {
        grid-column: 1 / -1;
        max-height: $full-width-image-height;
    }
}

@include layout.mqs-mq-medium {
    .adicta-full-width-image {
        margin-top: layout.$blue-whale-l + layout.$frog-s;

        > .responsive-picture {
            min-height: $full-width-image-height * 3;
        }
    }
}
