@use "../../layout";

.footer {
    background-color: layout.$black;
    color: layout.$white;
    font-family: layout.$main-font;
    font-size: layout.$bee-l;
    margin-top: layout.$flamingo-s;
    .footer-col {
        &.-newsletter {
            grid-column: 2 / 12;
            margin-top: layout.$frog-l;
        }
        &.-newsletter > .footer-newsletter-headline,
        &.-list:not(.-bottom-row) ul li:first-child {
            font-family: layout.$headline-font;
            text-transform: uppercase;
        }
        &.-newsletter > .footer-newsletter-content {
            margin-top: layout.$slug-l;
        }
        &.-newsletter > .block.-newsletter .form.subscribe {
            column-gap: layout.$bee-s;
            display: grid;
            grid-template-columns: repeat(12, 1fr);
        }
        &.-newsletter .field.newsletter .newsletter-field {
            background: layout.$black;
            border: solid 1px layout.$cape-cod;
            color: layout.$white;
            height: layout.$frog-l;
            padding: 0;
            text-indent: 10px;
        }
        &.-newsletter .field.newsletter {
            grid-column: 1 / 11;
        }
        &.-newsletter .actions > .action.subscribe.primary::before,
        &.-newsletter .actions > .action.subscribe.primary::after {
            display: none;
        }
        &.-newsletter .actions > .action.subscribe.primary {
            background: layout.$black;
            border: solid 1px layout.$cape-cod;
            height: layout.$frog-l;
            padding: 0;
            width: 100%;
        }
        &.-newsletter .actions {
            grid-column: 11 / 13;
        }
        &.-list {
            display: grid;
            list-style: none;
            margin-top: layout.$slug-l;
            padding: 0;
            row-gap: 15px;
        }
        &.-list a {
            color: layout.$white;
            text-decoration: none;
        }
        &.-list:nth-child(even) {
            grid-column: 2 / 7;
        }
        &.-list:nth-child(odd) {
            grid-column: 7 / 12;
        }
        &.-list.-bottom-row ul {
            margin-top: layout.$frog-s;
        }
        &.-scor-footer-logo {
            grid-column: 6/8;
            margin-bottom: layout.$slug-l;
            width: 53px;
        }
    }
}

@include layout.mqs-mq-medium {
    .footer {
        margin-top: layout.$orca-l;
        .footer-col {
            &.-newsletter {
                grid-column: 2 / 5;
            }
            &.-list {
                &:not(.-bottom-row) {
                    margin-top: layout.$frog-l;
                }
            }
            &.-list.-left-row {
                grid-column: 6 / 8;
            }
            &.-list.-center-row {
                grid-column: 8 / 10;
            }
            &.-list.-right-row {
                grid-column: 10 / 12;
            }
            &.-list.-bottom-row {
                grid-column: 8 / 12;
            }
            &.-list.-bottom-row,
            &.-scor-footer-logo {
                margin-top: layout.$godzilla-l;
            }
            &.-list.-bottom-row [data-enable-parallax] {
                height: 100%;
            }
            &.-list.-bottom-row [data-enable-parallax] > div {
                height: 100%;
            }
            &.-list.-bottom-row ul {
                display: flex;
                height: 100%;
                margin: 0;
            }
            &.-list.-bottom-row ul > li {
                align-self: flex-end;
                height: fit-content;
                margin: 0;
                padding: 0 10px;
            }
            &.-scor-footer-logo {
                grid-row: 2;
                justify-self: center;
                max-height: 112px;
                width: 70px;
            }
            > li {
                align-self: flex-end;
                display: flex;
                flex-direction: column;
            }
        }
    }
}
