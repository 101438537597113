@use "../../layout";

$support-max-height: 365px;
$support-min-height: 680px;

.adicta-support {
    grid-template-columns: repeat(12, 1fr);
    margin-top: layout.$orca-l;

    > .responsive-picture {
        grid-column: 1 / -1;
        min-height: $support-min-height;
    }

    > .filter {
        background-image: url("../../assets/filter/filter.webp");
        background-position: left;
        background-repeat: no-repeat;
        background-size: cover;
        grid-column: 1 / -1;
        max-height: 100%;
    }

    > .responsive-picture,
    > .headline,
    > .filter,
    > .items {
        grid-row: 1;
    }

    > .headline {
        @include layout.adicta-headline;
        color: layout.$white;
        font-size: 20px;
        grid-column: 2 / span 10;
        margin-top: layout.$flamingo-s;
        text-align: center;
    }

    > .items {
        align-items: center;
        align-self: center;
        display: block;

        grid-column: 3 / span 8;

        justify-content: center;
        margin-top: layout.$frog-s;

        text-align: center;
    }

    > .items > .adicta-support-item {
        margin-top: layout.$bear-s;
    }
}

@include layout.mqs-mq-medium {
    .adicta-support {
        grid-template-columns: repeat(16, 1fr);
        margin-top: layout.$godzilla-l;

        > .responsive-picture {
            max-height: $support-max-height;
            min-height: unset;
        }

        > .filter {
            background-image: url("../../assets/filter/filter_big.svg");
            background-position: center;
        }

        > .headline {
            font-size: 30px;
            grid-column: 5 / span 8;
        }

        > .items {
            display: flex;

            grid-column: 3 / span 12;

            justify-content: space-between;
        }
    }
}
