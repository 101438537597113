@use "../../../layout/media-queries" as mqs;
@use "./spaces";
@use "./fonts";

$max-content-width: 1440px;

$grid-s-margin: 12px;

$margin-s-fullscreen: spaces.$gnat-s;
$margin-l-fullscreen: spaces.$gnat-l;

$margin-s-almost-full: spaces.$bee-s;
$margin-l-almost-full: spaces.$frog-l;

$margin-s-content: spaces.$bee-s;
$margin-l-content: spaces.$godzilla-l;

$prefix: "scor";

%grid-s {
    display: grid;
    grid-template-columns: repeat(12, 1fr);
}

.#{$prefix}-grid {
    @extend %grid-s;

    &.-almost-full {
        grid-template-columns: $margin-s-almost-full repeat(12, 1fr) $margin-s-almost-full;
    }

    &.-copy {
        grid-template-columns: $margin-s-content repeat(12, 1fr) $margin-s-content;
    }
}

@include mqs.mq-medium {
    .#{$prefix}-grid {
        // @NOTE: See if this actually works per the design
        // There isn't anything defined for mobile.
        grid-template-columns: repeat(12, 1fr);

        &.-almost-full {
            grid-template-columns: $margin-l-almost-full repeat(12, 1fr) $margin-l-almost-full;
        }

        &.-copy {
            grid-template-columns: $margin-l-content repeat(12, 1fr) $margin-l-content;
        }
    }
}

%content-width {
    grid-template-columns: $grid-s-margin repeat(12, 1fr) $grid-s-margin;
    max-width: $max-content-width;
}

.#{$prefix}-content-area {
    @extend %content-width;

    > .page-main {
        padding: 0;
        [data-enable-parallax] {
            margin: 0 !important; /* needs to be overwritten by !important => values are set by JS */
            padding: 0 !important; /* needs to be overwritten by !important => values are set by JS */
        }
    }
}

// @NOTE: Workaround until we can get Magento Cloud to work
// with Layout overrides
// @SEE commit 651cea6efdc03e3937ee5d4bcb8d54f6f1ec6c80
// src/scor/theme.magento2.scor.base/Magento_Theme/layout/override/theme/BMC/makeover/cms_page_view.xml
.makeover-body {
    > .#{$prefix}-content-area {
        align-self: center;
    }
}

.#{$prefix}-body {
    display: flex;
    flex-direction: column;
    font-family: fonts.$main-font;

    // Attempt to fix Magento's strange font behavior by
    // resetting the font size back to the browser's default
    font-size: 16px;

    margin: 0;
    padding: 0;

    > .#{$prefix}-content-area {
        align-self: center;
    }
}
