@use "../../layout";

.scor-moodboard {
    margin-top: layout.$flamingo-s;

    > .headline {
        @include layout.scor-headline;
        font-size: 16px;

        // @NOTE: Not per design needs to be corrected in
        // Magento
        letter-spacing: 0;
    }

    > .headline,
    > .moods,
    > .scor-image-slider {
        grid-column: 2 / span 12;
    }
}

@include layout.mqs-mq-medium {
    .scor-moodboard {
        $max-height: 41.667vw;

        > .scor-image-slider .fallback {
            max-height: $max-height;
        }

        // @NOTE: This should be 20px and with the scor-headline mixin
        // but it's currently incorrect in Magento 2 on the PDP
        > .headline {
            font-family: layout.$headline-font;
            font-size: 22px;
        }

        > .moods,
        > .scor-image-slider {
            margin-top: layout.$slug-l * 2;
        }

        > .moods {
            display: grid;
            gap: 5px;
            grid-template-columns: 2fr 1fr 1fr;
            grid-template-rows: repeat(2, 1fr);

            > .mood:first-child {
                grid-column: 1 / span 1;
                grid-row: 1 / span 2;
                max-height: $max-height;
            }

            > .mood:not(:first-child) {
                max-height: calc(20.833vw - 5px);
            }

            > .mood:nth-child(2) {
                grid-column: 2 / span 1;
                grid-row: 1 / span 1;
            }

            > .mood:nth-child(3) {
                grid-column: 3 / span 1;
                grid-row: 1 / span 1;
            }

            > .mood:last-child {
                grid-column: 2 / span 2;
                grid-row: 2 / span 1;
            }

            > .mood > .responsive-picture {
                height: 100%;
                width: 100%;
            }
        }
    }
}

@include layout.mqs-mq-max-content {
    .scor-moodboard {
        $max-height: 600px;

        > .scor-image-slider .fallback {
            max-height: $max-height;
        }

        > .moods > .mood:first-child {
            grid-column: 1 / span 1;
            grid-row: 1 / span 2;
            max-height: $max-height;
        }

        .moods > .mood:not(:first-child) {
            max-height: calc(300px - 5px);
        }
    }
}
