$main-font: "GT America";
$headline-font: "GT America Expanded";

@font-face {
    font-display: swap;
    font-family: $main-font;
    font-style: normal;
    font-weight: 400;
    src: url("../../../assets/fonts/GT-America-Standard-Regular.woff2")
            format("woff2"),
        url("../../../assets/fonts/GT-America-Standard-Regular.woff")
            format("woff");
}

@font-face {
    font-display: swap;
    font-family: $headline-font;
    font-style: normal;
    font-weight: 700;
    src: url("../../../assets/fonts/GT-America-Expanded-Bold.woff2")
            format("woff2"),
        url("../../../assets/fonts/GT-America-Expanded-Bold.woff")
            format("woff");
}
