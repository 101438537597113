@use "../../layout";

.scor-text-module {
    margin-top: layout.$bear-s;
    > .scor-text-module-content-wrapper {
        grid-column: 2 / span 12;
        > .headline {
            font-family: layout.$headline-font;
            font-size: layout.$slug-l;
            margin: 0;
            text-transform: uppercase;
        }
        > .content {
            font-family: layout.$main-font;
            font-size: layout.$slug-l;
            margin: 0;
        }

        > .headline + .content {
            margin-top: layout.$bee-s;
        }
    }
}
