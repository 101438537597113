@use "../../../layout";

// stylelint-disable selector-class-pattern
.collection-overview .content-wrapper {
    .category-item {
        // @Note: Markup contains not only divs, that's why we didn't use "+" selector.
        > *:not(.responsive-picture):not(.headline) {
            margin-top: 20px;
        }

        > .responsive-picture {
            height: 230px;
            max-width: 230px;
        }

        > .headline,
        > .subheadline {
            letter-spacing: 5px;
            margin-bottom: 0;
            text-transform: uppercase;
        }

        > .headline {
            @include layout.adicta-headline;
            font-size: 24px;
            margin-top: 10px;
        }
    }

    // stylelint-disable selector-max-compound-selectors
    .splide {
        //@Note: moved by 60px to create the slider-center-mode.
        &__track {
            margin-right: 60px;
            overflow: visible;
            transform: translateX(60px);
        }

        &__slide .category-item *:not(.responsive-picture):not(img) {
            visibility: hidden;
        }

        &__slide.is-active .category-item *:not(.responsive-picture):not(img) {
            visibility: visible;
        }

        &__arrow--next {
            right: 0;
        }

        &__arrow--prev {
            left: 0;
        }
    }
}

@include layout.mqs-mq-medium {
    .collection-overview .content-wrapper {
        > .category-item {
            > *:not(.responsive-picture):not(.headline) {
                margin-top: 26px;
            }
        }
    }
}

@include layout.mqs-mq-max-content {
    .collection-overview .content-wrapper {
        > .category-item {
            > .responsive-picture {
                height: 430px;
                max-width: 430px;
            }
        }
    }
}
