@use "../../../layout";

.menu-group {
    color: layout.$mine-shaft;
    font-family: layout.$main-font;

    list-style: none;

    padding: 0;

    &.-root {
        > .expandable-list-item,
        > .tree-item {
            font-size: 22px;
        }

        > .expandable-list-item {
            font-weight: bold;
        }
    }

    + .menu-group {
        margin-top: layout.$flamingo-s;
    }
}
