@use "../../layout/spaces";
@use "../../../../layout/media-queries" as mqs;

.scor-gallery {
    margin-top: spaces.$bear-s;
    > .scor-gallery-headline {
        display: none;
    }
}

@include mqs.mq-medium {
    .scor-gallery {
        $max-height: 815px;

        margin-top: spaces.$titan-l;

        > .scor-image-slider .fallback {
            max-height: $max-height;
        }
    }
}
