@use "../../layout";
@use "../MakeoverImageSlider/MakeoverImageSlider.scss" as slider;

.makeover-category-collection {
    grid-template-rows: repeat(10, auto);
    margin-top: layout.$frog-s;
    > .container {
        grid-column: 1 / -1;
        grid-row: 1 / span 5;
        padding-bottom: layout.$bear-s;

        > .headline {
            @include layout.rwb-text();
            font-family: layout.$main-font;
            font-size: 30px;
            font-weight: bold;
            grid-column: 3 / span 11;
            margin: 0;
            margin-top: layout.$frog-s;
        }

        > .ctawrapper {
            display: none;
        }

        > .content-slider {
            display: grid;
            grid-column: 2 / span 12;
            margin-top: layout.$flamingo-s;
        }

        > .content-slider > .wrapper {
            grid-column: 1 / -1;
            overflow: hidden;
        }

        /* stylelint-disable selector-max-compound-selectors, selector-class-pattern, max-nesting-depth */
        > .content-slider > .wrapper > .splide {
            @include slider.makeover-pagination();
            display: grid;
            grid-template-columns: 60px 1fr 60px;

            .splide__track {
                grid-row: 1 / span 5;
            }

            // @NOTE: No longer relevant
            .splide__arrow {
                top: 40%;

                > svg {
                    fill: layout.$mine-shaft;
                    height: 27px;
                    width: 14px;
                }
            }

            .splide__track,
            .splide__pagination {
                grid-column: 1 / -1;
            }

            > .splide__arrows {
                grid-column: 1 / -1;
            }
        }
        /* stylelint-enable selector-max-compound-selectors, selector-class-pattern, max-nesting-depth */

        .content-slider .splide {
            max-width: unset;
        }
    }

    > .responsive-picture {
        grid-column: 3 / span 12;
        grid-row: 5 / span 4;
        margin-top: layout.$bee-s;
        min-height: 215px;
    }

    > .ctawrapper {
        grid-column: 3 / span 12;
    }
}

@include layout.mqs-mq-medium {
    .makeover-category-collection {
        grid-template-rows: repeat(5, auto);
        margin-top: layout.$flamingo-l;

        > .container > .ctawrapper {
            display: block;
        }

        > .container {
            grid-template-rows: repeat(6, auto);
            max-height: 500px;
            padding-bottom: layout.$slug-l;

            > .headline {
                font-size: 45px;
                grid-column: 3 / span 4;
            }

            > .content-slider {
                grid-column: 7 / span 7;
                grid-row: 1 / -1;
                margin-top: layout.$flamingo-l;
            }

            > .ctawrapper {
                display: block;
                grid-column: 3 / span 4;
                grid-row-start: 2;
            }

            /* stylelint-disable selector-max-compound-selectors, selector-class-pattern, max-nesting-depth */
            > .content-slider > .wrapper > .splide {
                .splide__track,
                .splide__pagination {
                    grid-column: 2 / span 1;
                }
            }
            /* stylelint-enable selector-max-compound-selectors, selector-class-pattern, max-nesting-depth */
        }

        > .ctawrapper {
            display: none;
        }

        > .responsive-picture {
            grid-column: 2 / span 5;
            grid-row: 4 / span 5;
            max-height: 310px;
        }

        &.-right {
            > .responsive-picture {
                grid-column-start: 9;
            }

            > .container > .headline,
            > .container > .ctawrapper {
                grid-column-start: 9;
            }

            > .container > .content-slider {
                grid-column: 2 / span 5;
            }
        }
    }
}
