@use "../../layout";

.collection-overview {
    margin-top: 55px;

    .content-wrapper {
        display: flex;
        gap: 40px;
        grid-column: 2 / span 12;

        &.slider-wrapper {
            display: initial;
            gap: unset;
        }
    }
}

@include layout.mqs-mq-medium {
    .collection-overview {
        margin-top: 80px;
    }
}
