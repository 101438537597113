@use "../layout/spaces";

@mixin linkcolor($color) {
    &:link,
    &:visited,
    &:focus,
    &:hover,
    &:active {
        color: $color;
    }
}

%cta-link {
    @include linkcolor(currentColor);

    border: 1px solid currentColor;

    display: inline-block;
    padding: spaces.$ant-s spaces.$frog-s;

    text-decoration: none;
}
