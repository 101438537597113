@use "../../../layout";

.highlight-item {
    $highlight-item-content-alignment: 50%;
    $highlight-item-min-height: 102.778vw;
    position: relative;

    > .responsive-picture {
        max-height: $highlight-item-min-height;
        // It's important for IOS 14 and older devices, if the container has height of 100%.
        min-height: $highlight-item-min-height;

        .fallback {
            max-height: inherit;
            min-height: inherit;
        }
    }

    // @TODO: Revisit this section to improve it
    .content-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        left: $highlight-item-content-alignment;
        position: absolute;
        text-align: center;
        top: $highlight-item-content-alignment;
        transform: translate(
            -$highlight-item-content-alignment,
            -$highlight-item-content-alignment
        );
        // 15px on both horizontal sides on mobile.
        width: calc(100% - 30px);

        > .copy {
            @include layout.adicta-copy;
            @include layout.bw-text;
            // Inserting the max amount of char (265) here will make the headline overlap the icon,
            // on the hand the font size was not defined in the mobile Variant, so we needed to adjust it depending on the max char number.
            font-size: 12px;
            letter-spacing: normal;
            margin: 0;
            margin-top: layout.$frog-s;
            text-transform: none;
        }

        > .headline {
            @include layout.adicta-headline;
            color: layout.$white;
            margin: 0;
            margin-top: layout.$slug-s;
            // Two lines headline
            max-width: 175px;
        }

        > .icon {
            width: 90px;
        }
    }
}

@include layout.mqs-mq-medium {
    .highlight-item {
        > .responsive-picture {
            max-height: 29.861vw;
            min-height: auto;
        }

        .content-wrapper {
            // calculated approx. depending on the sentence's width from Design - 265 char
            width: 80%;

            > .headline {
                margin-top: layout.$frog-s;
            }

            > .copy {
                font-size: 15px;
                margin-top: layout.$frog-s;
            }

            > .icon {
                width: 134px;
            }
        }
    }
}

@include layout.mqs-mq-max-content {
    .highlight-item {
        > .responsive-picture {
            max-height: 430px;
        }
    }
}
