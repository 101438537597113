@use "../../layout";

.product-teaser {
    $max-height: 450px;
    margin-top: layout.$slug-s;
    > .content {
        display: grid;
        grid-column: 2 / span 10;
        grid-template-columns: repeat(12, 1fr);
    }
    > .content > .cta-container {
        grid-column: 1 / span 9;
        > .product-teaser-headline {
            @include layout.rwb-text();
            font-family: layout.$main-font;
            font-size: layout.$frog-s;
            font-weight: 700;
            margin: 0;
            margin-top: layout.$flamingo-s;
        }
        .cta {
            display: none;
        }
    }
    > .content > .responsive-picture {
        grid-column: 1 / span 12;
        max-height: $max-height;
        width: 100%;
    }
    > .content > .responsive-picture > img {
        object-fit: contain;
    }
    > .responsive-picture {
        grid-column: 3 / span 10;
        grid-row: 2;
        margin-top: layout.$slug-s;
        max-height: $max-height;
        z-index: 1;
    }
    > .cta {
        grid-column: 3 / 10;
        grid-row: 4;
        text-align: center;
        z-index: 1;
    }
    .cta.-white {
        color: layout.$red-ribbon;
    }
    &::after {
        background: linear-gradient(to top, layout.$white 50%, transparent 50%);
        content: "";
        display: block;
        grid-column: 1 / -1;
        grid-row: 2 / span 5;
        z-index: 0;
    }
}

@include layout.mqs-mq-medium {
    .product-teaser {
        margin-top: layout.$blue-whale-l;
        > .content > .cta-container {
            grid-column: 1 / span 3;
            > .cta {
                display: inline-block;
            }
            .cta.-white {
                color: layout.$white;
            }
        }
        > .content > .responsive-picture {
            grid-column: 6 / span 12;
            margin-top: layout.$flamingo-s;
            width: 100%;
        }
        .cta {
            display: none;
        }
        > .responsive-picture {
            grid-column: 1 / span 5;
            height: unset;
            margin-top: -170px;
        }
        &::after {
            grid-column: 6 / span 7;
            grid-row: 2;
        }
    }
}
