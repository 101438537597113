@use "../../layout";

.adicta-teaser {
    color: layout.$white;
    margin-top: 80px;
    position: relative;

    > .content-wrapper {
        align-self: center;
        grid-column: 3 / span 10;
        grid-row: 1;

        > .headline {
            @include layout.adicta-headline;
            @include layout.bw-text;
            font-size: 20px;
            line-height: 30px;
        }
    }

    > .responsive-picture {
        grid-column: 2 / 14;
        grid-row: 1;
        min-height: 600px;
    }

    .actions {
        @include layout.adicta-copy;
        max-width: 60%;
    }

    .sidebar-text {
        @include layout.adicta-headline;
        @include layout.bw-text;
        bottom: calc(0% + 100px);
        color: layout.$white;
        letter-spacing: 5px;
        position: absolute; //@note container is rotated and took more space after rotating it, that's why the position absolute instead of grid
        right: 0;
        transform: rotate(270deg);
        white-space: nowrap;
    }
}

// ab 768px
@include layout.mqs-mq-medium {
    .adicta-teaser {
        margin-top: 120px;

        > .responsive-picture {
            max-height: 530px;
        }

        > .content-wrapper {
            grid-column: 3 / span 5;
            letter-spacing: 5px; // @Note: It's not clear in layout

            > .headline {
                font-size: 30px;
                line-height: 50px;
            }
        }

        .actions {
            max-width: 50%;
        }

        .sidebar-text {
            align-self: center;
            bottom: unset;
            grid-column: 13;
            grid-row: 1;
            position: unset;
            right: unset;
            transform: rotate(270deg) translateX(-50%);
        }
    }
}
