@use "../../layout";
.support {
    margin-top: layout.$flamingo-s;

    .icon-link:not(:nth-child(1)):not(:nth-child(2)) > .icon-wrapper {
        margin-top: layout.$frog-l;
    }

    .inner-wrapper {
        background: layout.$mischka;
        color: layout.$red-ribbon;
        font-family: layout.$main-font;
        grid-column: 2 / span 12;
        padding: 0 layout.$bee-l layout.$orca-l;
        text-align: center;
        > .headline {
            font-size: layout.$frog-s;
            margin-bottom: 0;
            margin-top: layout.$flamingo-l;
        }
        > .icons-wrapper {
            margin-top: layout.$orca-l;
        }

        > .icons-wrapper > a {
            color: layout.$red-ribbon;
            display: inline-flex;
            flex-direction: column;
            text-decoration: none;
            width: 50%;
        }
        > .icons-wrapper .icon-label {
            font-size: layout.$slug-s;
            margin-top: layout.$slug-l;
        }

        .icon-link {
            align-items: center;
            &:nth-child(odd):last-child {
                grid-column: 1 / span 2;
            }
        }
        .icon-link > .icon-wrapper {
            align-items: center;
            display: flex;
            height: 100px;
            justify-content: center;
            width: 100px;
        }
    }
}

@include layout.mqs-mq-medium {
    .support {
        margin-top: layout.$godzilla-l;

        .icons-wrapper {
            > .icon-link:not(:nth-child(1)):not(:nth-child(2)) > .icon-wrapper {
                margin-top: 0;
            }
        }
        .inner-wrapper {
            .icons-wrapper {
                margin-top: layout.$bear-s;
            }
            > .headline {
                font-size: layout.$flamingo-s;
                margin-top: layout.$frog-l;
            }
            > .icons-wrapper {
                display: flex;
            }
            .icon-link {
                grid-row: 1;
            }
        }
    }
}
