/*
    Hide content without impacting SEO or a11y

    See:
    https://www.w3.org/WAI/tutorials/forms/labels/#note-on-hiding-elements

*/
@mixin visually-hidden {
    clip-path: inset(50%) rect(0 0 0 0);
    height: 1px;

    overflow: hidden;
    position: absolute;

    white-space: nowrap;

    width: 1px;
}

/*
    Remove default styles from lists
*/
@mixin unlist-a-list {
    list-style: none;
    margin: 0;
    padding: 0;
}

/*
    Add shadows when scrolling content is hidden so the
    user is actually aware that there's content.

    Used mainly in the mobile navigation as the design didn't
    account for scrolling/overflow of content.

    https://lea.verou.me/2012/04/background-attachment-local/
    https://css-scroll-shadows.vercel.app/
*/
@mixin scroll-shadows($backgroundColor) {
    background: linear-gradient($backgroundColor 33%, rgba(158, 166, 239, 0)),
        linear-gradient(rgba(158, 166, 239, 0), $backgroundColor 66%) 0 100%,
        radial-gradient(
            farthest-side at 50% 0,
            rgba(34, 34, 34, 0.5),
            rgba(0, 0, 0, 0)
        ),
        radial-gradient(
                farthest-side at 50% 100%,
                rgba(34, 34, 34, 0.5),
                rgba(0, 0, 0, 0)
            )
            0 100%;
    background-attachment: local, local, scroll, scroll;
    background-color: $backgroundColor;
    background-repeat: no-repeat;
    background-size: 100% 45px, 100% 45px, 100% 15px, 100% 15px;
}

// @NOTE: Inspired from https://stackoverflow.com/a/54107923/362253
@mixin horizonal-line-background($lineColor, $thickness: 1.5px) {
    background: linear-gradient(
        to bottom,
        transparent calc(50% - #{$thickness}),
        $lineColor calc(50% - #{$thickness}) calc(50% + #{$thickness}),
        transparent calc(50% + #{$thickness})
    );
}

// Remove all default styles from a <button/>
@mixin unstyled-button {
    background: none;
    border: none;
    font-family: inherit;
    margin: 0;
    padding: 0;
}

// @NOTE: https://rscss.io/helpers.html
/* stylelint-disable selector-class-pattern */
._visuallyhidden {
    @include visually-hidden();
}

._hidden {
    display: none;
}
/* stylelint-enable selector-class-pattern */

// When the navigation is shown on mobile
// we don't want the page to scroll
.-bmc-no-scroll {
    overflow-y: hidden;
}
