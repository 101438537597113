@use "../../layout";

.scor-image-slider {
    > .image-slider {
        grid-column: 2 / span 12;
    }
}

// Buttons Top
/* stylelint-disable max-nesting-depth, selector-class-pattern */
.scor-image-slider {
    &.-top {
        > .image-slider {
            > .wrapper {
                > .splide {
                    display: flex;
                    flex-direction: column-reverse;
                }
            }
        }

        .splide__pagination {
            all: unset;
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            > li,
            > li > .splide__pagination__page {
                width: 100%;
            }

            > li:not(:last-child) {
                margin-right: layout.$ant-s;
            }

            .splide__pagination__page {
                @include layout.horizonal-line-background(layout.$nobel);
                border-radius: none;
                margin: layout.$gnat-s;
                padding: layout.$slug-s;

                &.is-active {
                    @include layout.horizonal-line-background(layout.$black);
                    transform: unset;
                }
            }
        }
    }

    &.-bottom {
        .splide__pagination {
            all: unset;
            display: flex;
            flex-direction: row;
            justify-content: center;
            margin-top: layout.$slug-s;

            > li,
            > li > .splide__pagination__page {
                max-width: 60px;
                width: 100%;
            }

            > li:not(:last-child) {
                margin-right: layout.$ant-s;
            }

            .splide__pagination__page {
                @include layout.horizonal-line-background(layout.$nobel);
                border-radius: none;
                margin: layout.$gnat-s;
                padding: layout.$slug-s;

                &.is-active {
                    @include layout.horizonal-line-background(layout.$nobel);
                    transform: unset;
                }

                // Create this strange green bar
                &.is-active::before {
                    background-color: layout.$cruise;
                    content: "";
                    display: block;
                    height: 3px;
                    margin-left: -15px;
                    margin-top: -3px;
                    transform: rotate(5deg);
                    width: 43px;
                }
            }
        }
    }
}

@include layout.mqs-mq-medium {
    .scor-image-slider {
        &.-bottom {
            .splide__pagination {
                margin-top: -(layout.$bee-l * 4);

                > li {
                    margin-bottom: layout.$bee-l * 2;
                }

                > li,
                > li > .splide__pagination__page {
                    max-width: 182px;
                }

                .splide__pagination__page {
                    // Create this strange green bar
                    &.is-active::before {
                        transform: rotate(2deg);
                        width: 134px;
                    }
                }
            }
        }
    }
}
/* stylelint-enable max-nesting-depth, selector-class-pattern */
