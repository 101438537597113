@use "../../../layout";

.brand-teaser-pictures {
    grid-template-rows: repeat(5, 65px);

    > .responsive-picture:first-of-type {
        grid-column: 1 / span 7;
        grid-row: 1 / span 2;
    }

    > .responsive-picture:nth-of-type(2) {
        grid-column: 5 / span 7;
        grid-row: 4 / span 2;
        padding-bottom: layout.$slug-s;
    }

    > .responsive-picture:nth-child(3) {
        display: none;
    }
}

@include layout.mqs-mq-medium {
    .brand-teaser-pictures {
        grid-template-rows: repeat(10, 60px);

        > .responsive-picture:first-of-type {
            grid-column: 1 / span 5;
            grid-row: 1 / span 5;
        }

        > .responsive-picture:nth-of-type(2) {
            grid-column: 4 / span 3;
            grid-row: 7 / span 4;
        }

        > .responsive-picture:nth-child(3) {
            display: block;
            grid-column: 9 / span 3;
            grid-row: 3 / span 4;
        }
    }
}
