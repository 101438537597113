@use "../../layout";

.makeover-teaser {
    font-family: layout.$main-font;
    margin-top: layout.$slug-s;

    > .responsive-picture,
    > .content {
        grid-row: 1;
    }

    &.-fullscreen {
        > .responsive-picture {
            grid-column: 1 / -1;
            z-index: 1;
        }
    }

    > .responsive-picture {
        grid-column: 2 / -2;
        min-height: 600px;
        z-index: 1;
    }

    > .content {
        grid-column: 3 / span 8;
        margin-top: layout.$frog-s;
        z-index: 2;

        .page-headline {
            font-size: layout.$frog-s;
            font-weight: bold;
        }

        > .motto {
            @include layout.rwb-text();
            color: layout.$white;
            font-size: layout.$bee-l;

            margin: 0;
        }
    }
}

@include layout.mqs-mq-medium {
    .makeover-teaser {
        margin-top: layout.$blue-whale-l;
        > .content {
            grid-column: 3 / span 3;
            > .page-headline {
                font-size: layout.$frog-l;
                margin-top: layout.$frog-s;
            }
            > .motto {
                margin-top: layout.$blue-whale-l;
            }
        }
        > .responsive-picture {
            max-height: 750px;
        }
    }
}
