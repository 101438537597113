@use "../../layout";

.makeover-newsletter {
    align-items: center;
    background-color: layout.$red-ribbon;
    color: layout.$white;
    font-family: layout.$main-font;
    margin-top: layout.$slug-s;
    min-height: 600px;

    > .content {
        grid-column: 2 / span 12;
        padding-left: layout.$slug-s;
        padding-right: layout.$slug-s;
    }

    > .content > .headline,
    > .content > .privacy {
        margin: 0;
    }

    > .content > .headline {
        font-size: 30px;
        font-weight: bold;
        margin-top: layout.$flamingo-s;

        &.-small {
            font-size: 16px;
            margin-top: layout.$gnat-s;
        }

        &.-small + .headline {
            margin-top: layout.$frog-s;
        }
    }

    > .content > .signup-form {
        margin-top: layout.$bear-s;
    }

    > .content > .privacy {
        font-size: 12px;
        margin-top: layout.$frog-s;

        > .link {
            @include layout.linkcolor(layout.$white);
        }
    }
}

@include layout.mqs-mq-medium {
    .makeover-newsletter {
        margin-top: layout.$frog-l;
        min-height: 450px;

        > .content {
            display: grid;
            grid-column: 2 / span 12;
            grid-template-columns: repeat(12, 1fr);
            padding-left: layout.$gnat-l;
            padding-right: layout.$gnat-l;

            > .headline + .headline {
                margin-top: layout.$gnat-l;
            }

            > .headline {
                font-size: 45px;
                margin-top: layout.$gnat-l;
            }

            > .headline.-small {
                font-size: 14px;
            }

            > .headline.-small + .headline {
                margin-top: layout.$gnat-l;
            }
        }

        > .content > .headline.-small,
        > .content > .headline:nth-child(2) {
            grid-column: 1 / span 4;
        }

        > .content > .headline:nth-child(3),
        > .content > .signup-form,
        > .content > .message,
        > .content > .privacy {
            grid-column: 6 / span 5;
        }

        > .content > .signup-form {
            margin-top: layout.$frog-l;
        }

        > .content > .privacy {
            margin-top: layout.$slug-l;
        }
    }
}
