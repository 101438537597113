@use "../../layout";

.home-slider2 {
    font-family: layout.$main-font;

    > .content {
        column-gap: 5px;
        display: grid;
        grid-column: 2 / -2;
        grid-template-columns: repeat(12, minmax(auto, 1fr));
        padding-bottom: 100%;

        > .page-headline {
            grid-column: 2 / span 8;
        }

        > .motto {
            @include layout.rwb-text();
            color: layout.$white;
            font-size: layout.$slug-s;
            grid-column: 2 / span 7;

            margin: 0;
            margin-top: layout.$bee-s;
        }

        > .cta-wrapper {
            grid-column: 2 / span 12;
        }
    }

    > .responsive-picture {
        grid-column: 3 / -1;
        margin-top: calc(-100% + #{layout.$frog-s});
        max-height: 70.833vw;
        // force render the image on mobile if this selector has height 100%
        min-height: 70.833vw;
    }
}

@include layout.mqs-mq-medium {
    .home-slider2 {
        > .content {
            column-gap: 26.67px;

            display: grid;
            grid-column: 2 / -2;
            grid-row: 1;
            grid-template-columns: repeat(12, minmax(auto, 1fr));
            grid-template-rows: repeat(3, max-content);
            margin-top: 0;
            padding-bottom: 0;

            > .page-headline,
            > .motto,
            > .cta {
                z-index: 2;
            }

            > .page-headline {
                grid-column: 2 / span 7;
            }

            // @TODO: Extract into its own component?
            > .motto {
                font-size: layout.$frog-l;
                grid-column: 2 / span 5;
                margin-top: layout.$slug-l;
            }

            > .cta-wrapper {
                grid-column: 2 / span 3;
            }
        }

        > .responsive-picture {
            grid-column: 6 / -1;
            grid-row: 1;
            margin-top: layout.$frog-l;
            max-height: 577px;
            min-height: auto;
            padding-bottom: layout.$godzilla-l;
        }
    }
}
