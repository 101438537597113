@use "../../../../layout";

.tree-item {
    font-size: 15px;
    margin-left: layout.$bee-l;

    margin-top: layout.$slug-s;

    > a {
        @include layout.linkcolor(currentColor);
        text-decoration: none;
    }
}
