@use "sass:math";
@use "../../layout";

.makeover-store-switcher {
    background-color: layout.$white;
    border-bottom: 1px solid layout.$dusty-gray;
    position: sticky;
    top: 0;
    z-index: layout.$sticky-navigation;
    > .storelist {
        @include layout.unlist-a-list();
        align-items: center;

        display: flex;

        grid-column: 2 / span 12;

        > .storeitem {
            &:first-child {
                margin-left: -(math.div(layout.$slug-s, 2));
            }
        }

        > .storeitem > a {
            @include layout.linkcolor(layout.$mine-shaft);

            display: block;

            padding: layout.$bee-s math.div(layout.$slug-s, 2);

            text-decoration: none;
        }

        > .storeitem.-active > a {
            color: layout.$red-ribbon;
        }
    }
}
