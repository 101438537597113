@use "../../layout";

.scor-home-slider1 {
    align-items: center;
    display: grid;
    font-family: layout.$main-font;
    grid-template-columns: layout.$gnat-s repeat(12, 1fr) layout.$gnat-s;
    grid-template-rows: layout.$bear-s max-content layout.$bear-s;
    justify-content: center;

    > .content {
        align-items: center;
        grid-column: 1 / -1;
        grid-row: 2 / span 1;
        justify-content: flex-start;
        z-index: 1;

        > .headline-container {
            grid-column: 3 / span 10;
            grid-row: 1 / span 2;
            text-align: center;
            z-index: 2;
        }

        > .headline-container > .page-headline {
            @include layout.scor-headline();
            font-size: 30px;
            margin-top: 0;
        }

        > .responsive-picture {
            grid-column: 1 / -1;
            grid-row: 1 / span 2;
            height: 100%;
        }
    }

    > .responsive-picture {
        grid-column: 1 / -1;
        grid-row: 1 / span 3;
        height: 100%;
    }
}

@include layout.mqs-mq-medium {
    .scor-home-slider1 {
        $grid-gap: 40px;
        column-gap: $grid-gap;
        grid-template-columns: (layout.$blue-whale-l - $grid-gap) repeat(
                12,
                1fr
            ) (layout.$blue-whale-l - $grid-gap);
        grid-template-rows: layout.$t-rex-l max-content layout.$t-rex-l;

        > .content {
            grid-column: 2 / span 12;

            > .headline-container {
                column-gap: $grid-gap;
                display: grid;
                grid-template-columns: repeat(8, 1fr);
            }

            > .headline-container > .page-headline {
                font-size: 60px;
                grid-column: 1 / -1;
            }

            > .headline-container > .scor-cta {
                grid-column: 3 / span 4;
            }
        }

        &.-left {
            > .content > .headline-container {
                grid-column: 2 / span 8;
                text-align: left;
            }
            > .content > .headline-container > .scor-cta {
                grid-column: 1 / span 4;
            }
        }

        &.-right {
            > .content > .headline-container {
                grid-column: 7 / span 7;
                text-align: right;
            }
            > .content > .headline-container > .scor-cta {
                grid-column: 5 / span 4;
            }
        }
    }
}
