@use "../../layout";

.underlined-link {
    > .link {
        @include layout.adicta-headline;
        border-bottom: 2px solid layout.$red-orange;
        color: layout.$red-orange;
        text-decoration: none;
    }
}

@include layout.mqs-mq-medium {
    .underlined-link {
        > .link {
            padding-bottom: 2px;

            &:hover,
            &:focus {
                border-bottom-color: layout.$black;
                color: layout.$black;
            }
        }
    }
}
