@use "../../layout";

.bmc-highlight-carousel {
    margin-top: 65px;

    > .content-slider {
        grid-column: 1 / -1;

        // Slider center mode
        // stylelint-disable selector-class-pattern
        .splide__track {
            overflow: visible;
            transform: translateX(70px);
        }

        .splide__arrow {
            top: 50%;
            transform: translateY(-50%);
        }
    }
}

@include layout.mqs-mq-medium {
    .bmc-highlight-carousel {
        grid-gap: 30px;
        margin-top: 120px;

        > .bmc-highlight-item:nth-child(1) {
            grid-column: 2 / span 4;
        }

        > .bmc-highlight-item:nth-child(2) {
            grid-column: 6 / span 4;
        }

        > .bmc-highlight-item:nth-child(3) {
            grid-column: 10 / span 4;
        }
    }
}
