@use "../../layout";
@use "../../features/scor/layout/colors" as scor-colors;
@use "../../features/scor/layout/fonts" as scor-fonts;

.bmc-sticky-link {
    .bmc-icon-link {
        align-items: center;
        display: inline-flex;
        font-size: 14px;
        text-decoration: none;

        > .label {
            display: block;
            margin-left: layout.$bee-s;

            // @NOTE: custom value
            margin-top: -2px;
        }

        > .pinsvg {
            height: 29px;
            width: 12px;
        }

        > .chatsvg {
            height: 23px;
            width: 20px;
        }
    }

    &.-adicta {
        background-color: layout.$black;

        > .bmc-icon-link {
            @include layout.linkcolor(layout.$white);
            font-family: layout.$main-font;
        }
    }

    &.-makeover {
        background-color: layout.$red-ribbon;

        > .bmc-icon-link {
            @include layout.linkcolor(layout.$white);
            font-family: layout.$main-font;
        }
    }

    &.-scor {
        background-color: layout.$black;

        > .bmc-icon-link {
            @include layout.linkcolor(scor-colors.$cruise);
            font-family: scor-fonts.$main-font;
            font-weight: bold;
        }

        // @NOTE: The other brands use a transparent background
        // whereas Scor has its filled
        /* stylelint-disable selector-max-compound-selectors, max-nesting-depth */
        > .bmc-icon-link > .chatsvg > g {
            > path:first-child {
                fill: scor-colors.$cruise;
            }

            > path:nth-child(2) {
                stroke: layout.$black;
            }
        }
        /* stylelint-enable selector-max-compound-selectors, max-nesting-depth */
    }
}

@include layout.mqs-mq-medium {
    .bmc-sticky-link {
        width: 100%;
        // @TODO: Change to "sidways-lr" once supported
        writing-mode: vertical-rl;

        /* stylelint-disable a11y/media-prefers-reduced-motion */
        &,
        > .bmc-icon-link {
            transform: translate(0);

            // @TODO: Rework this to not use padding
            transition: all 200ms ease-in;
        }
        /* stylelint-enable a11y/media-prefers-reduced-motion */

        &:hover,
        &:focus {
            padding-right: 15px;
            transform: translate(-15px);
        }

        &.-makeover {
            &:hover,
            &:focus {
                background-color: layout.$mine-shaft;
            }
        }

        .bmc-icon-link {
            font-size: 12px;
            margin-top: layout.$gnat-s;
            padding: 9px 11px 15px 9px;
            > .label {
                margin-left: layout.$gnat-l;
                margin-top: layout.$bee-l;
                transform: rotate(-180deg);
            }

            > .pinsvg {
                height: 32px;
                width: 15px;
            }
        }
    }
}

@media screen and (prefers-reduced-motion: reduce) {
    .bmc-sticky-link {
        &.-adicta {
            &,
            > .bmc-icon-link {
                transition: none;
            }

            &:hover,
            &:focus {
                transform: none;
            }
        }
    }
}
